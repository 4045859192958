import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import "./Articles.css";
import test1 from "../assets/images/like.svg";
import test2 from "../assets/images/comment.svg";
import { getSocket } from "../utils/socket";

function Articles() {
  const [articles, setArticles] = useState([]);
  const [heroData, setHeroData] = useState({ title: "", subtitle: "" });
  const [selectedCategory, setSelectedCategory] = useState("عام");

  useEffect(() => {
    const socket = getSocket();
  
    const handleArticleUpdate = (newArticles) => {
      setArticles(newArticles);
    };
  
    const handleConnect = () => {
      socket.on("lastThreeArticlesFetched", handleArticleUpdate);
    };
  
    if (socket && socket.connected) {
      socket.on("lastThreeArticlesFetched", handleArticleUpdate);
    } else if (socket) {
      socket.once("connect", handleConnect);
    }
  
    return () => {
      if (socket) {
        socket.off("lastThreeArticlesFetched", handleArticleUpdate);
        socket.off("connect", handleConnect);
      }
    };
  }, []);
  
  

  // Fetch data on mount
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [heroRes, articleRes] = await Promise.all([
          axios.get(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero2`),
          axios.get(`${process.env.REACT_APP_IMGNBACKEND}/articles/getLastThree`),
        ]);
        setHeroData({
          title: heroRes.data?.sections[0]?.label || "",
          subtitle: heroRes.data?.sections[2]?.label || "",
        });
        setArticles(articleRes.data || []);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return description;
  };

  const filteredArticles = articles.filter((article) => {
    if (selectedCategory === "عام") return true;
    if (selectedCategory === "الأحدث") return article.publishStatus.toLowerCase() === "latest";
    if (selectedCategory === "حصري") return article.publishStatus.toLowerCase() === "exclusive";
    if (selectedCategory === "عاجل") return article.publishStatus.toLowerCase() === "breaking";
    return false;
  });

  const categories = ["عام", "حصري", "عاجل", "الأحدث"];

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <section className="articles-section">
      <h2 className="articles-title">{heroData.title || "تخيل كل جديد"}</h2>
      <p className="articles-subtitle">{heroData.subtitle}</p>
      <div className="articles-categories">
        {categories.map((category) => (
          <button
            key={category}
            className={`category-button ${selectedCategory === category ? "active" : ""}`}
            onClick={() => setSelectedCategory(category)}
          >
            {category === "عام" ? category : capitalizeFirstLetter(category)}
          </button>
        ))}
      </div>
      <div className="articles-grid">
        {filteredArticles.length === 0 ? (
          <div className="no-articles-message">
            <p>لا توجد مقالات متاحة لهذا الفلتر.</p>
          </div>
        ) : (
          <div className="articles-page-grid">
            {filteredArticles.map((article) => (
              <Link key={article._id} to={`/articles/${article.uniqueId}`} className="article-card-custom-link">
                <div className="article-card-custom">
                  <div className="article-card-custom-image-container">
                    <img src={article.cardImage} alt={article.title} className="article-card-custom-image" loading="lazy" />
                  </div>
                  <div className="article-card-custom-content">
                    <div className="article-tag-date">
                      {article.publishStatus && (
                        <span className={`article-tag-custom ${article.publishStatus.toLowerCase()}`}>
                          {capitalizeFirstLetter(article.publishStatus)}
                        </span>
                      )}
                      <p className="article-date">
                        {new Date(article.createdAt).toLocaleDateString()} at {new Date(article.createdAt).toLocaleTimeString()}
                      </p>
                    </div>
                    <h3 className="article-card-custom-title">{article.title}</h3>
                    <p className="article-card-custom-description">
                      {truncateDescription(article.description, 20)}
                    </p>
                    <div className="article-stats-custom">
                      <span className="article-likes">
                        {article.likes.length} <img src={test1} alt="Likes Icon" />
                      </span>
                      <span className="article-comments">
                        {article.comments.length} <img src={test2} alt="Comments Icon" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default Articles;
