import axios from "axios";
import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

// Create an Axios instance
const api = axios.create({
  baseURL: process.env.REACT_APP_IMGNBACKEND, // Your backend URL
  withCredentials: true, // Ensure cookies are sent with requests
});

// Helper function to redirect to the correct login page
const redirectToLogin = () => {
  const role = localStorage.getItem("role");
  if (role === "admin") {
    window.location.href = "/admin/login"; // Redirect admin to /admin/login
  } else {
    window.location.href = "/signin"; // Redirect user to /signin
  }
};

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem("accessToken");
    if (token) {
      const decodedToken = jwtDecode(token); // Decode the token to check expiry
      if (decodedToken.exp * 1000 < Date.now()) {
        // Token is expired, refresh it
        try {
          const newAccessToken = await refreshAccessToken();
          localStorage.setItem("accessToken", newAccessToken); // Update the access token
          config.headers.Authorization = `Bearer ${newAccessToken}`; // Attach the new token to the request
        } catch (error) {
          console.error("Error refreshing token:", error);
          toast.error("Session expired. Please log in again.");
          clearLocalStorage();
          redirectToLogin(); // Use the updated redirection logic
        }
      } else {
        // Token is still valid, attach it to the request
        config.headers.Authorization = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true; // Mark the request as retried
      try {
        const newAccessToken = await refreshAccessToken();
        localStorage.setItem("accessToken", newAccessToken); // Update the access token
        originalRequest.headers.Authorization = `Bearer ${newAccessToken}`; // Retry the request with the new token
        return api(originalRequest);
      } catch (error) {
        console.error("Error refreshing token:", error);
        toast.error("Session expired. Please log in again.");
        clearLocalStorage();
        redirectToLogin(); // Use the updated redirection logic
      }
    }
    return Promise.reject(error);
  }
);

// Helper function to refresh the access token
const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  const role = localStorage.getItem("role"); // 👈 detect role
  if (!refreshToken) throw new Error("No refresh token found");

  // Choose the correct endpoint dynamically
  const endpoint =
    role === "admin"
      ? "/admin/refresh-token"
      : "/users/refresh-token"; // ✅ fallback to user

  const response = await axios.post(
    `${process.env.REACT_APP_IMGNBACKEND}${endpoint}`,
    { refreshToken },
    { withCredentials: true }
  );

  if (response.data.accessToken) {
    console.log("🔄 Access token refreshed successfully:", response.data.accessToken);
    return response.data.accessToken;
  } else {
    throw new Error("Failed to refresh token");
  }
  
};


// Helper function to clear localStorage
const clearLocalStorage = () => {
  localStorage.removeItem("accessToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("admin");
  localStorage.removeItem("user");
  localStorage.removeItem("role"); // Clear the role as well
};

export default api;