import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import api from "../../../utils/axiosConfig";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TinyMCEEditor from "../../../components/TinyMCEEditor";
import GridEditor from "../GridSystem/GridEditor";
import { FaSave } from "react-icons/fa";
import { ReactComponent as FolderAdd } from "../../../assets/images/folder-add.svg";
import checkmarkIcon from "../../../assets/images/checkmark.png";
import "./Styles/EditArticle.css";

const EditArticle = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(false);
  const [uniqueId, setUniqueId] = useState("");
  const [cardImage, setCardImage] = useState(null);
  const [cardImagePreview, setCardImagePreview] = useState(null);
  const [images, setImages] = useState([]);
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [hero, setHero] = useState("");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [publishStatus, setPublishStatus] = useState("common");
  const [layout, setLayout] = useState([]);
  const [closure, setClosure] = useState("");
  const [authorName, setAuthorName] = useState("Author");

  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (location.state?.editArticleId) {
      fetchArticle(location.state.editArticleId);
    }
    fetchTags();
    fetchAdminProfile();
  }, [location]);

  const fetchArticle = useCallback(async (idOrUniqueId) => {
    setLoading(true);
    try {
      const response = await api.get(`/articles/unique/${idOrUniqueId}`, {
        params: { populate: "author,editor" },
      });
      const articleData = response.data;
  
      // Check if the article is approved and enforce role-based access
      const loggedInUser = JSON.parse(localStorage.getItem("admin"));
      const loggedInUserRole = loggedInUser?.role;
  
      if (articleData.reviewStatus === "approved" ||articleData.reviewStatus === "declined") {
        if (loggedInUserRole === "writer") {
          toast.error("❌ Writers cannot edit approved articles.");
          setLoading(false);
          return;
        }
        if (loggedInUserRole === "editor" && articleData.editor._id !== loggedInUser.id) {
          toast.error("❌ You can only edit articles you have been assigned as the editor.");
          setLoading(false);
          return;
        }
      }
  
      setArticle(articleData);
      setUniqueId(articleData.uniqueId);
      setTitle(articleData.title);
      setSubTitle(articleData.subTitle);
      setHero(articleData.hero);
      setDescription(articleData.description);
      setTag(articleData.tag._id);
      setPublishStatus(articleData.publishStatus);
      setLayout(articleData.layout);
      setCardImagePreview(articleData.cardImage);
      setImages(articleData.images.map(url => ({ url })));
      setClosure(articleData.closure);
    } catch (error) {
      toast.error("❌ لم يتم العثور على المقال!");
      navigate(location.pathname, { replace: true, state: {} });
    }
    setLoading(false);
  }, [navigate, location]);
  
  
  const fetchTags = useCallback(async () => {
    try {
      const response = await api.get("/tags");
      setTags(response.data);
    } catch (error) {
      console.error("Error fetching tags:", error);
      toast.error("❌ خطأ في تحميل الفلاتر");
    }
  }, []);

  const fetchAdminProfile = useCallback(async () => {
    try {
      const response = await api.get("/admin/me");
      setAuthorName(response.data.full_name || response.data.nickname || "Admin");
    } catch (error) {
      console.error("Error fetching admin profile:", error);
    }
  }, []);

  const handleImageChange = (e, setImageFunc, setPreviewFunc) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImageFunc(file);
        setPreviewFunc(reader.result);
        toast.success("✅ تم تحميل الصورة بنجاح!", { autoClose: 3000 });
      };
      reader.readAsDataURL(file);
    } else {
      toast.error("❌ فشل تحميل الصورة!", { autoClose: 3000 });
    }
  };

  const handleMultiFileChange = (e) => {
    const files = Array.from(e.target.files).map(file => ({
      file,
      preview: URL.createObjectURL(file)
    }));
    setImages(prev => [...prev, ...files]);
  };

  const handleDeleteImage = (index) => {
    if (index === undefined) {
      setCardImage(null);
      setCardImagePreview(null);
    } else {
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    }
  };

  const handleSubmit = async () => {
    if (!title.trim() || !description.trim() || !tag) {
      toast.warning("⚠️ Please fill in all required fields!");
      return;
    }
  
    const loggedInUser = JSON.parse(localStorage.getItem("admin"));
    const loggedInUserRole = loggedInUser?.role;
  
    // Check if the article is approved and enforce role-based access
    if (article?.publishStatus === "approved") {
      if (loggedInUserRole === "writer") {
        toast.error("❌ Writers cannot edit approved articles.");
        return;
      }
      if (loggedInUserRole === "editor" && article.editor._id !== loggedInUser.id) {
        toast.error("❌ You can only edit articles you have been assigned as the editor.");
        return;
      }
    }
  
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("title", title);
      formData.append("subTitle", subTitle);
      formData.append("hero", hero);
      formData.append("description", description.replace(/<\/?[^>]+(>|$)/g, ""));
      formData.append("tag", tag);
      formData.append("publishStatus", publishStatus);
      formData.append("layout", JSON.stringify(layout));
      formData.append("closure", closure);
  
      if (cardImage) formData.append("cardImage", cardImage);
  
      images.forEach((image) => {
        if (image.file) {
          formData.append("images", image.file);
        }
      });
  
      await api.put(`/articles/${article._id}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      toast.success("✅ Article updated successfully!");
  
      setTimeout(() => {
        resetForm();
        navigate(location.pathname, { replace: true, state: {} });
      }, 3000);
    } catch (error) {
      console.error("🚨 Error updating article:", error);
      toast.error(`❌ Error updating article! ${error.response?.data?.error || ""}`);
    }
    setLoading(false);
  };
  

  const resetForm = () => {
    setArticle(null);
    setUniqueId("");
    setCardImage(null);
    setCardImagePreview(null);
    setImages([]);
    setTitle("");
    setSubTitle("");
    setHero("");
    setDescription("");
    setTag("");
    setPublishStatus("common");
    setLayout([]);
    setClosure("");
  };

  const handleStopEditing = () => {
    resetForm();
    navigate(location.pathname, { replace: true, state: {} });
  };

  return (
    <div className="add-article-container">
      <div className="article-search">
        <label>كود الموضوع المراد تعديله</label>
        <input
          type="text"
          placeholder="ادخل كود المقال"
          value={uniqueId}
          onChange={(e) => setUniqueId(e.target.value)}
        />
        <button onClick={() => fetchArticle(uniqueId)}>بحث</button>
        {article && (
          <button onClick={handleStopEditing} style={{ marginLeft: '10px' }}>
            إيقاف التعديل
          </button>
        )}
      </div>

      {article ? (
        <>
          <div className="settings-guide">
            <p>دليل الإعداد والنشر</p>
            <div className="participation-container">
              <div className="participation-box">
                <span className="participation-label">دور المشاركة</span>
                <div className="participation-input">كتابة وتحضير</div>
              </div>
              <div className="participant-name-box">
                <span className="participant-label">إسم الكاتب</span>
                <div className="participant-input">{article?.author?.fullName || "غير معروف"}</div>
              </div>
              <div className="participation-box" style={{ marginTop: '10px' }}>
                <span className="participation-label">دور المشاركة</span>
                <div className="participation-input">مراجعة وتحرير</div>
              </div>
              <div className="participant-name-box" style={{ marginTop: '10px' }}>
                <span className="participant-label">إسم المحرر</span>
                <div className="participant-input">{article?.editor?.fullName || "غير معروف"}</div>
              </div>
            </div>
          </div>

          <div className="category-section">
            <span className="category-label">ينتمي إلى:</span>
            <div className="category-dropdown">
              <select value={tag} onChange={(e) => setTag(e.target.value)} className="article-input-filter">
                <option value="">أختر الفلتر</option>
                {tags.map((t) => (
                  <option key={t._id} value={t._id}>{t.tag}</option>
                ))}
              </select>
            </div>
          </div>

          <div className="publish-status-section">
            <p>حالة النشر:</p>
            <div className="publish-options">
              {["common", "latest", "breaking", "exclusive"].map((status) => (
                <div
                  key={status}
                  className={`check-mark-container ${publishStatus === status ? "selected" : ""}`}
                  onClick={() => setPublishStatus(status)}
                >
                  <div className="custom-checkmark-icon">
                    {publishStatus === status && <img src={checkmarkIcon} alt="✔" />}
                  </div>
                  <span>
                    {status === "common" ? "شائع Common" :
                     status === "latest" ? "الأحدث Latest" :
                     status === "breaking" ? "عاجل Breaking" :
                     "حصري Exclusive"}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <input type="text" placeholder="عنوان المقال" value={title} onChange={(e) => setTitle(e.target.value)} className="article-input" />
          <input type="text" placeholder="العنوان الفرعي" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} className="article-input" />
          <input type="text" placeholder="رابط الصورة الرئيسية (في حالة عدم وجود رابط يرجى وضع عنوان المقال هنا مرة أخرى)" value={hero} onChange={(e) => setHero(e.target.value)} className="article-input" />
          <br />
          <p>وصف الغلاف:</p>
          <TinyMCEEditor value={description} onChange={setDescription} />

          <div className="dashboard-custom-images">
            <div className="dashboard-custom-images-section">
              <label>صورة الكارت</label>
              {cardImagePreview ? (
                <div className="dashboard-custom-images-item">
                  <img src={cardImagePreview} alt="Platform Icon" className="dashboard-custom-images-preview" />
                  <button className="dashboard-custom-images-delete" onClick={() => handleDeleteImage()}>
                    ✖
                  </button>
                </div>
              ) : (
                <label className="dashboard-custom-images-item upload">
                  <FolderAdd className="dashboard-custom-images-icon" />
                  <input type="file" accept="image/*" onChange={(e) => handleImageChange(e, setCardImage, setCardImagePreview)} style={{ display: "none" }} />
                </label>
              )}
            </div>

            <div className="dashboard-custom-images-section">
              <label>تحميل الصور الإضافية</label>
              <div className="dashboard-custom-images-grid">
                {images.map((image, index) => (
                  <div key={index} className="dashboard-custom-images-item">
                    <img src={image.url ? image.url : image.preview} alt={`Image ${index}`} className="dashboard-custom-images-preview" />
                    <button className="dashboard-custom-images-delete" onClick={() => handleDeleteImage(index)}>
                      ✖
                    </button>
                  </div>
                ))}
                <label className="dashboard-custom-images-item upload">
                  <FolderAdd className="dashboard-custom-images-icon" />
                  <input type="file" accept="image/*" multiple onChange={handleMultiFileChange} style={{ display: "none" }} />
                </label>
              </div>
            </div>
          </div>

          <GridEditor layout={layout} setLayout={setLayout} />

          <input type="text" placeholder="الخاتمة" value={closure} onChange={(e) => setClosure(e.target.value)} className="article-input" />

          <div className="article-buttons">
            <button className="review-button" onClick={handleSubmit} disabled={loading}>
              <FaSave /> حفظ التغيرات
            </button>
          </div>

          {loading && (
            <div className="loading-container">
              <div className="loading-spinner"></div>
              <p className="loading-text">⏳ جاري التحميل...</p>
            </div>
          )}
        </>
      ) : (
        <div className="settings-guide">
          <p>يرجى إدخال كود المقال للبدء في التعديل.</p>
        </div>
      )}
      <ToastContainer />
    </div>
  );
};

export default EditArticle;
