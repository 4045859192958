import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import { getSocket } from "../utils/socket"; // assuming this returns initialized socket
import "./HeroSection.css";

function HeroSection() {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [prevImageIndex, setPrevImageIndex] = useState(0);
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [heroData, setHeroData] = useState(null);
  const [loading, setLoading] = useState(true);

  const isMounted = useRef(true);
  const intervalRef = useRef(null);

  useEffect(() => {
    let isMounted = true;
    const socket = getSocket();
  
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero1`);
        if (isMounted) {
          setHeroData(response.data);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching Hero Section:", error);
        if (isMounted) setLoading(false);
      }
    };
  
    fetchHeroData();
  
    const handleUpdate = ({ sectionId }) => {
      if (sectionId === "hero1") {
        console.log("🔄 Live HeroSection1 update received");
        fetchHeroData(); // Refetch on backend broadcast
      }
    };
  
    if (socket && socket.connected && !socket.hasListeners("heroSectionUpdated")) {
      socket.on("heroSectionUpdated", handleUpdate);
    }
     else {
      const wait = setInterval(() => {
        const s = getSocket();
        if (s && s.connected) {
          s.on("heroSectionUpdated", handleUpdate);
          clearInterval(wait);
        }
      }, 300);
    }
  
    return () => {
      isMounted = false;
      if (socket) socket.off("heroSectionUpdated", handleUpdate);
    };
  }, []);
  

  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedAdmin = localStorage.getItem("admin");
    if (storedUser) setUser(JSON.parse(storedUser));
    if (storedAdmin) setAdmin(JSON.parse(storedAdmin));
  }, []);

  useEffect(() => {
    if (heroData?.carouselCharacters?.length) {
      const interval = setInterval(() => {
        setPrevImageIndex(currentImageIndex);
        setCurrentImageIndex((prev) => (prev + 1) % heroData.carouselCharacters.length);
      }, 5000);
      return () => clearInterval(interval);
    }
  }, [heroData, currentImageIndex]);

  if (loading) return <p>Loading Hero Section...</p>;

  return (
    <section className="hero">
      <div className="hero-background">
        <img src="/rec.png" alt="Background" className="background-image" />
      </div>

      <div className="hero-image-container">
        {heroData?.carouselCharacters?.map((character, index) => (
          <img
            key={index}
            src={character.url}
            alt={`Character ${index + 1}`}
            className={`hero-media ${
              index === currentImageIndex
                ? "fade-right-in"
                : index === prevImageIndex
                ? "fade-right-out"
                : ""
            }`}
          />
        ))}
      </div>

      <div className="hero-content">
        <h5>{heroData?.sections[2]?.label}</h5>
        <h1>{heroData?.sections[0]?.label}</h1>
        <p>{heroData?.sections[4]?.label}</p>

        <div className="her">
          {!user && !admin && (
            <Link to="/signin" className="hero-button">
              كن متخيلًا الآن
            </Link>
          )}
          <Link
            to="#"
            className="hero-button-2"
            onClick={() => window.open("https://discord.gg/imgnarabhp", "_blank")}
          >
            إنضم لمجتمعنا الفعال على ديسكورد
          </Link>
        </div>
      </div>
    </section>
  );
}

export default HeroSection;
