import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AboutUsSection.css";

function AboutUsSection() {
  const [aboutData, setAboutData] = useState({
    title: "",
    sections: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchAboutData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero6`);
        setAboutData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("❌ Error fetching About Us section:", error);
        setLoading(false);
      }
    };

    fetchAboutData();
  }, []);

  if (loading) return <p>⏳ جاري التحميل...</p>;
  if (!aboutData.sections.length) return <p>❌ لا يوجد بيانات لهذا القسم.</p>;

  return (
    <section className="about-us-section">
      <div className="about-us-box">
        {/* Now correctly displaying "من نحن" dynamically */}
        <h3 className="about-us-title">{aboutData.sections[0]?.label}</h3>

        {aboutData.sections.slice(1).map((section, index) => (
          <p key={index} className="about-us-paragraph">
            {section.label}
          </p>
        ))}

        <button className="about-us-button">اعرفنا اكتر</button>
      </div>
    </section>
  );
}

export default AboutUsSection;
