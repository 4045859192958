import React, { useState, useEffect, useCallback, useContext,useRef  } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../utils/AuthContext";
import api from "../utils/axiosConfig";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { ToastContainer, toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import ChangePasswordModal from "./ChangePasswordModal";
import "./react-datepicker-custom.css";

import "./settings.css";
import headerlogo from "../assets/images/Settings-BG.png";
import profilePic from "../assets/images/def-pic.png";
import logo from "../assets/images/Logo (Main).png";
import editIcon from "../assets/images/edit-icon.png";
import googleIcon from "../assets/images/google-icon.png";
import facebookIcon from "../assets/images/facebook-icon.png";
import discordIcon from "../assets/images/discord-icon.png";
import checkmarkIcon from "../assets/images/checkmark.png";
import keyIcon from "../assets/images/key-icon.svg";
import cloudinaryUploadIcon from "../assets/images/gallery-edit.svg";

const Settings = () => {
  const navigate = useNavigate();
  const { user, admin, isReady } = useContext(AuthContext);
  const [userData, setUserData] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showBirthDate, setShowBirthDate] = useState(false);
  const [showFacebook, setShowFacebook] = useState(false);
  const [bioText, setBioText] = useState("");
  const [wordCount, setWordCount] = useState(0);
  const [editMode, setEditMode] = useState({});
  const [editedData, setEditedData] = useState({});
  const [originalData, setOriginalData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const isLoggedIn = isReady && (user || admin);
  const hasFetchedRef = useRef(false);
  const [emailError, setEmailError] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(true);
  
  const apiEndpoint = user ? "/users/me" : admin ? "/admin/me" : "";
 
  const fetchData = useCallback(async () => {
    try {
      
      const res = await api.get(apiEndpoint);
      const data = res.data;
     
      setUserData(data);
      const parsedBirthday = data.birthday ? new Date(data.birthday) : null;
      setEditedData({
        full_name: data.full_name || "",
        nickname: data.nickname || "",
        phone_number: data.phone_number || "",
        email: data.email || "",
        birthday: parsedBirthday,
        bio: data.bio || "",
      });
      setOriginalData({ ...data, birthday: parsedBirthday });
      setBioText(data.bio || "");
      setWordCount(data.bio ? data.bio.trim().split(/\s+/).length : 0);
      setEditMode({
        full_name: false,
        nickname: false,
        phone_number: false,
        email: false,
        birthday: false,
        bio: false,
      });
    } catch (err) {
      console.error("Error fetching data:", err);
      toast.error("Failed to load profile. Please try again.");
      navigate(user ? "/signin" : "/admin/login");
    } finally {
      setIsLoading(false);
    }
  }, [apiEndpoint, navigate, user]);

  useEffect(() => {
    if (isLoggedIn && !hasFetchedRef.current) {
      hasFetchedRef.current = true;
      fetchData();
    }
  }, [isLoggedIn, fetchData]);
  
  
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditedData({ ...editedData, [name]: value });
  
    if (name === "email") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value.trim())) {
        setEmailError("Please enter a valid email address.");
        setIsEmailValid(false);
      } else {
        setEmailError("");
        setIsEmailValid(true);
      }
    }
  };
  

  const handleDateChange = (date) => {
    setEditedData({ ...editedData, birthday: date });
  };

  const toggleEdit = (field) => {
    setEditMode((prev) => ({ ...prev, [field]: !prev[field] }));
    if (editMode[field]) {
      setEditedData({ ...editedData, [field]: originalData[field] });
    }
  };

  const handleSave = async (field) => {
    try {
      let fieldToUpdate = field;
  
      // Adjust field name based on user type
      if (field === "full_name" && admin) {
        fieldToUpdate = "fullName";
      }
  
  
      const res = await api.put(apiEndpoint, { [fieldToUpdate]: editedData[field] });
  
      setUserData((prev) => ({
        ...prev,
        ...res.data,
      }));
  
      setEditMode({ ...editMode, [field]: false });
      setOriginalData({ ...originalData, [field]: editedData[field] });
  
      if (field === "bio") {
        setBioText(editedData.bio);
        setWordCount(editedData.bio.trim().split(/\s+/).length);
      }
  
      toast.success("Saved successfully");
    } catch (err) {
      console.error("Error saving data:", err);
      toast.error("Failed to save. Please try again.");
    }
  };
  
  
  

  const handleProfilePicChange = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
  
    // 🔸 Show instant preview
    const previewURL = URL.createObjectURL(file);
    setUserData((prev) => ({
      ...prev,
      profile_picture: previewURL,
    }));
  
    setIsUploading(true);
    const formData = new FormData();
    formData.append("profile_picture", file);
  
    try {
      const res = await api.put(apiEndpoint, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });
  
      // 🔸 Update with the actual uploaded image URL
      const uploadedURL = res.data.user?.profile_picture || res.data.profile_picture;
  
      setUserData((prev) => ({
        ...prev,
        profile_picture: uploadedURL,
      }));
  
      toast.success("Profile picture updated");
    } catch (err) {
      console.error("Error uploading image:", err);
      toast.error("Upload failed. Please try again.");
  
      // 🔸 Optionally revert preview if upload fails
      setUserData((prev) => ({
        ...prev,
        profile_picture: originalData?.profile_picture || profilePic,
      }));
    } finally {
      setIsUploading(false);
      // 🔸 Revoke the object URL to prevent memory leaks
      URL.revokeObjectURL(previewURL);
    }
  };
  
  const fieldLabels = {
    full_name: "الاسم بالكامل",
    nickname: "الاسم المستعار",
    phone_number: "رقم الهاتف",
    email: "البريد الإلكتروني",
    birthday: "تاريخ الميلاد",
    bio: "وصف شخصي",
  };

  if (!isLoggedIn) return null;


  if (!user && !admin) {
    navigate("/"); // or /admin/login if needed
    return null;
  }
  
  if (isLoading || !userData) return <div>Loading...</div>;
  

  const profileImage = isUploading ? (
    <div className="loading-container">Uploading...</div>
  ) : (
    <img
      className="profile-picture"
      src={userData?.profile_picture || profilePic}
      alt="User Profile"
      onError={(e) => {
        e.target.onerror = null;
        e.target.src = profilePic; // Fallback to default image if user's image fails to load
      }}
    />
  );

  return (
    <div className="settings-page">
      <div className="settings-container">
        <div className="settings-header">
          <div className="header-background">
            <img src={headerlogo} alt="Header Background" />
          </div>
          <div className="header-logo-container">
            <img src={logo} alt="Logo" className="header-logo" />
            <span className="header-text">Encircle The World</span>
          </div>
        </div>

        <div className="header-line"></div>

        <div className="profile-container" onClick={() => document.getElementById("file-input").click()}>
          {profileImage}
          <div className="hover-overlay">
            <div className="icon-container">
              <img src={cloudinaryUploadIcon} alt="Edit" className="upload-icon" />
              <span>Change profile picture</span>
            </div>
          </div>
          <input
            type="file"
            id="file-input"
            style={{ display: "none" }}
            onChange={handleProfilePicChange}
            accept="image/jpeg, image/png, image/jpg, image/webp, image/svg, image/gif, image/avif"
          />
        </div>

        <div className="personal-info-container">
          <div className="section-title">
            <span>{fieldLabels.full_name}</span>
          </div>
          <div className="info-content">
            {["full_name", "nickname", "phone_number", "email", "birthday"].map((field, index) => (
              <div className="info-row" key={index} dir="rtl">
                <span className="info-label">{fieldLabels[field]}</span>
                {editMode[field] ? (
                  field === "birthday" ? (
                    <DatePicker
                      selected={editedData.birthday}
                      onChange={handleDateChange}
                      dateFormat="yyyy-MM-dd"
                      showYearDropdown
                      showMonthDropdown
                      dropdownMode="select"
                      excludeDates={[]} 
                      showOutsideDays={false}
                      yearDropdownItemNumber={100}
                      scrollableYearDropdown
                      maxDate={new Date("2012-12-31")}
                      minDate={new Date("1900-01-01")}
                      calendarClassName="custom-datepicker-calendar"
                      dayClassName={() => "custom-datepicker-day"}
                      wrapperClassName="custom-datepicker-wrapper"
                      customInput={
                        <input
                          style={{
                    
                            textAlign: "center", // 👈 Moves numbers like 12345 to the left
                          }}
                        />
                      }
                    />




                  ) : (
                    <input
                      type="text"
                      name={field}
                      value={editedData[field]}
                      onChange={handleInputChange}
                    />
                  )
                ) : (
                  <span className="info-value">{field === "birthday" ? (editedData.birthday ? editedData.birthday.toLocaleDateString() : "") : editedData[field]}</span>
                )}
                <div className="edit-icon" onClick={() => toggleEdit(field)}>
                  <img src={editIcon} alt="Edit" />
                </div>
                {field === "email" && editMode.email && emailError && (
                        <div className="error-message" style={{ color: "red", fontSize: "0.8rem", marginTop: "4px" }}>
                            {emailError}
                            </div>
                            )}
                            {editMode[field] && (
                            <button
                            className="save-button"
                            onClick={() => handleSave(field)}
                            disabled={field === "email" && !isEmailValid}
                            >
                        Save
                </button>
                )}


              </div>
            ))}
          </div>
        </div>

        <div className="bio-container">
          <div className="section-title">
            <span>{fieldLabels.bio}</span>
          </div>
          <div className="bio-content">
            {editMode.bio ? (
              <textarea
                value={editedData.bio}
                onChange={(e) => setEditedData({ ...editedData, bio: e.target.value })}
                className="bio-textarea"
              />
            ) : (
              <div className="bio-box" dir="rtl">
                <span className="bio-text">{bioText || "No bio available."}</span>
                <span className="word-count">{wordCount} كلمة</span>
              </div>
            )}
            <div className="edit-icon" onClick={() => toggleEdit("bio")}> <img src={editIcon} alt="Edit" /></div>
            {editMode.bio && <button className="save-button" onClick={() => handleSave("bio")}>Save</button>}
          </div>
        </div>

        <div className="other-accounts-container">
          <div className="section-title"><span>حسابات أخرى</span></div>
          <div className="accounts-content">
            <div className="account-row" dir="rtl">
              <img src={googleIcon} alt="Google" className="account-icon" />
              <span className="account-value">{userData?.linked_accounts?.google || 'Coming Soon'}</span>
              <span className="account-label">جوجل</span>
            </div>
            <div className="account-row" dir="rtl">
              <img src={facebookIcon} alt="Facebook" className="account-icon" />
              <span className="account-value">{userData?.linked_accounts?.facebook || 'Coming Soon'}</span>
              <span className="account-label">فيسبوك</span>
            </div>
            <div className="account-row" dir="rtl">
              <img src={discordIcon} alt="Discord" className="account-icon" />
              <span className="account-value">{userData?.linked_accounts?.discord || 'Coming Soon'}</span>
              <span className="account-label">ديسكورد</span>
            </div>
          </div>
        </div>

        <div className="achievement-container">
          <div className="section-title"><span>الإنجاز</span></div>
          <div className="info-content">
            <div className="info-row" dir="rtl">
              <span className="info-text">اتقل تاخد حاجه نضيفه , هتدلع ❤️</span>
            </div>
          </div>
        </div>

        <div className="horizontal-line"></div>

        <div className="privacy-container">
          <div className="change-password" onClick={() => setIsModalOpen(true)}>
            <span className="password-text">تغيير كلمة المرور</span>
            <img className="password-icon" src={keyIcon} alt="Key Icon" />
          </div>
          <ChangePasswordModal isOpen={isModalOpen} onClose={() => setIsModalOpen(false)} />

          <div className="privacy-options">
            <div className="privacy-option" onClick={() => setShowBirthDate(!showBirthDate)}>
              <div className={`checkbox ${showBirthDate ? "checked" : ""}`}>{showBirthDate && <img src={checkmarkIcon} alt="Checkmark" />}</div>
              <span className="privacy-label">إظهار تاريخ الميلاد</span>
            </div>
            <div className="privacy-option" onClick={() => setShowFacebook(!showFacebook)}>
              <div className={`checkbox ${showFacebook ? "checked" : ""}`}>{showFacebook && <img src={checkmarkIcon} alt="Checkmark" />}</div>
              <span className="privacy-label">إظهار حساب الفيسبوك</span>
            </div>
          </div>
        </div>

        <div className="horizontal-line-2"></div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Settings;
