import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../utils/axiosConfig";
import { toast } from "react-toastify";
import { getSocket } from "../../../utils/socket";
import "./Styles/ArticlesHistory.css";
import { ReactComponent as ViewCmsIcon } from '../../../assets/images/view-cms.svg'; // Adjust the path as needed
import { ReactComponent as EditCmsIcon } from '../../../assets/images/edit-cms.svg'; // Adjust the path as needed

// Format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day} ${month}, ${year}, ${hours}:${minutes}`;
};

// Review status label
const getStatusLabel = (status, declineReason) => {
  switch (status) {
    case "draft":
      return "لم يتم المراجعة بعد";
    case "under_review":
      return "يتم المراجعة من قبل المحرر";
    case "declined":
      return `تم الرفض (${declineReason || "لا يوجد سبب"})`;
    case "approved":
      return null;
    default:
      return status;
  }
};

const ArticlesHistory = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loggedInUser = JSON.parse(localStorage.getItem("admin")) || null;
  const loggedInUserId = loggedInUser ? loggedInUser.id : null;

  const socket = getSocket();

  const fetchArticles = useCallback(async () => {
    if (!loggedInUserId) return;

    try {
      setLoading(true);
      const response = await api.get(`/articles`);
      const userArticles = response.data.data.filter(
        (article) => article.author?._id === loggedInUserId
      );
      setArticles(userArticles);
      setFilteredArticles(userArticles);
    } catch (error) {
      console.error("🚨 Error fetching articles:", error);
      toast.error("فشل تحميل المقالات.");
    } finally {
      setLoading(false);
    }
  }, [loggedInUserId]);

  useEffect(() => {
    if (!loggedInUserId) {
      console.warn("⚠️ No admin in localStorage.");
      setLoading(false);
      return;
    }

    fetchArticles();

    if (socket) {
      socket.on("connect", () => {
        console.log("🟢 Connected to socket for ArticlesHistory");
      });

      // Real-time syncing events
      const syncEvents = [
        "articleCreated",
        "articleUpdated",
        "articleDeleted",
        "articleSoftDeleted",
        "articlePublished",
        "publishRequestReviewed",
        "rePublishRequested",
        "articleDeclined",
      ];

      syncEvents.forEach((event) => socket.on(event, fetchArticles));

      // ✅ Custom notification for article under review by editor
      socket.on(`article-review-started-${loggedInUserId}`, ({ title, editor }) => {
        toast.info(`📄 مقال "${title}" قيد المراجعة من قبل: ${editor}`);
      });

      return () => {
        syncEvents.forEach((event) => socket.off(event, fetchArticles));
        socket.off("connect");
        socket.off(`article-review-started-${loggedInUserId}`);
      };
    }
  }, [socket, fetchArticles, loggedInUserId]);

  useEffect(() => {
    if (!searchQuery.trim()) {
      setFilteredArticles(articles);
      return;
    }

    const normalized = searchQuery.toLowerCase();
    const results = articles.filter((article) => {
      const formattedDate = formatDate(article.updatedAt).toLowerCase();
      return (
        article.title.toLowerCase().includes(normalized) ||
        article.uniqueId.toLowerCase().includes(normalized) ||
        formattedDate.includes(normalized)
      );
    });

    setFilteredArticles(results);
  }, [searchQuery, articles]);

  return (
    <div className="articles-history-wrapper">
      <div className="search-container">
        <input
          type="text"
          placeholder="🔍 أبحث عن الموضوع"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar-cms"
        />
        <p className="search-info">
          يمكن البحث بإستخدام كود الموضوع (Topic ID) أو أسمه أو تاريخ النشر
        </p>
      </div>

      <div className="articles-history-container">
        <h2 className="history-title">سجل المقالات</h2>

        {loading ? (
          <p className="loading-text">⏳ جاري تحميل المقالات...</p>
        ) : (
          <table className="articles-table">
            <thead>
              <tr>
                <th>Topic ID</th>
                <th>عنوان</th>
                <th>الحالة / تاريخ النشر</th>
                <th>إجراءات</th>
              </tr>
            </thead>
            <tbody>
              {filteredArticles.length > 0 ? (
                filteredArticles.map((article) => {
                  const statusLabel = getStatusLabel(article.reviewStatus, article.declineReason);
                  const isDeclined = article.reviewStatus === "declined";
                  const isApproved = article.reviewStatus === "approved";
                  const isAuthor = article.author?._id === loggedInUserId;
                  const canEdit = !isApproved && isAuthor;

                  return (
                    <tr key={article._id}>
                      <td>{article.uniqueId.toUpperCase()}</td>
                      <td>{article.title}</td>
                      <td>
                        {statusLabel || formatDate(article.updatedAt)}
                      </td>
                                      <td>
                  {!isDeclined && (
                    <div className="article-actions">
                      <button
                        className="icon-button view"
                        onClick={() => window.open(`/articles/${article.uniqueId}`, "_blank")}
                      >
                        <ViewCmsIcon className="cms-icon" />
                      </button>
                      {canEdit && (
                        <button
                          className="icon-button edit"
                          onClick={() =>
                            navigate("/admin/dashboard", {
                              state: { editArticleId: article.uniqueId },
                            })
                          }
                        >
                          <EditCmsIcon className="cms-icon" />
                        </button>
                      )}
                    </div>
                  )}
                </td>

                    </tr>
                  );
                })
              ) : (
                <tr>
                  <td colSpan="4" className="no-data">لا توجد مقالات.</td>
                </tr>
              )}
            </tbody>
          </table>
        )}

        <p className="text-info">
          يمكنك من خلال الأزرار الجانبية معاينة / تعديل أي موضوع تم أختياره
        </p>
      </div>
    </div>
  );
};

export default ArticlesHistory;
