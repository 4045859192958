import React, { useState, useEffect, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import api from "../../../utils/axiosConfig";
import { toast, ToastContainer } from "react-toastify";
import { FaEye, FaEdit, FaCheck, FaTimes } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import "./Styles/ReviewRequests.css";
import { getSocket } from "../../../utils/socket";

// Format date
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const day = date.getDate().toString().padStart(2, "0");
  const month = date.toLocaleString("en-US", { month: "short" });
  const year = date.getFullYear();
  const hours = date.getHours().toString().padStart(2, "0");
  const minutes = date.getMinutes().toString().padStart(2, "0");
  return `${day} ${month}, ${year}, ${hours}:${minutes}`;
};

// Review status label
const getStatusLabel = (status) => {
  switch (status) {
    case "approved":
      return "✅ تم النشر";
    case "under_review":
      return "🕒 قيد المراجعة";
    case "declined":
      return "❌ تم الرفض";
    case "draft":
    default:
      return "📝 مسودة";
  }
};

const ReviewRequests = () => {
  const [articles, setArticles] = useState([]);
  const [filteredArticles, setFilteredArticles] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const loggedInUser = JSON.parse(localStorage.getItem("admin")) || null;
  const loggedInUserId = loggedInUser?.id;
  const userRole = loggedInUser?.role?.toLowerCase();
  const isWriter = userRole === "writer";
  const isSuperAdminOrCEO = ["super_admin", "ceo"].includes(userRole);

  const fetchReviewArticles = useCallback(async () => {
    try {
      setLoading(true);
      const response = await api.get("/articles");
      const allArticles = response.data?.data || [];
      setArticles(allArticles);
      setFilteredArticles(allArticles);
    } catch (err) {
      toast.error("❌ فشل تحميل المقالات");
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    const socket = getSocket();
    if (!socket || !socket.connected || !loggedInUserId || isWriter) return;

    const handleArticleUpdate = (data) => {
      console.log(`📡 Real-time event received:`, data);
      fetchReviewArticles();

      // Show notification for new submissions
      if (data.reviewStatus === "draft") {
        toast.info(`📝 مقال جديد يحتاج إلى مراجعة: ${data.title}`);
      }
    };

    const syncEvents = [
      "articleCreated",
      "articleUpdated",
      "articleDeleted",
      "articleSubmitted",
      "articleSoftDeleted",
      "articlePublished",
      "publishRequestReviewed",
      "rePublishRequested",
      "articleDeclined",
    ];

    // Handle reconnection to re-fetch data
    socket.on("connect", () => {
      console.log("🔄 Socket reconnected");
      fetchReviewArticles();
    });

    // Register all sync events
    syncEvents.forEach((event) => socket.on(event, handleArticleUpdate));

    // Cleanup listeners
    return () => {
      socket.off("connect");
      syncEvents.forEach((event) => socket.off(event, handleArticleUpdate));
    };
  }, [fetchReviewArticles, loggedInUserId, isWriter]);

  useEffect(() => {
    const socket = getSocket();
    if (!socket || !socket.connected) {
      console.warn("⚠️ Socket not initialized or not connected yet.");
      return;
    }

    socket.on("article-review-started", ({ articleId, editor }) => {
      setArticles((prev) =>
        prev.map((a) =>
          a._id === articleId ? { ...a, reviewStatus: "under_review", editor: { fullName: editor } } : a
        )
      );
    });

    socket.on("connect", () => {
      console.log("✅ Socket connected with ID:", socket.id);
    });

    socket.on("disconnect", () => {
      console.warn("❌ Socket disconnected");
    });

    return () => {
      socket.off("connect");
      socket.off("disconnect");
    };
  }, []);

  useEffect(() => {
    const socket = getSocket();
    if (!socket || !loggedInUserId || isWriter) return;

    const handleReconnect = () => {
      console.log("🔄 Reconnected, syncing review requests...");
      fetchReviewArticles();
    };

    const handleArticleUpdate = () => {
      console.log("📡 Article update received");
      fetchReviewArticles();
    };

    socket.on("connect", handleReconnect);

    const events = [
      "articleCreated",
      "articleUpdated",
      "articleDeleted",
      "articleSoftDeleted",
      "articlePublished",
      "publishRequestReviewed",
      "rePublishRequested",
      "articleDeclined",
    ];

    events.forEach((event) => socket.on(event, handleArticleUpdate));

    // Initial load
    fetchReviewArticles();

    return () => {
      socket.off("connect", handleReconnect);
      events.forEach((event) => socket.off(event, handleArticleUpdate));
    };
  }, [loggedInUserId, isWriter, fetchReviewArticles]);

  useEffect(() => {
    const normalized = searchQuery.trim().toLowerCase();
    if (!normalized) {
      setFilteredArticles(articles);
    } else {
      const results = articles.filter((article) => {
        const formattedDate = formatDate(article.updatedAt).toLowerCase();
        return (
          article.title?.toLowerCase().includes(normalized) ||
          article.uniqueId?.toLowerCase().includes(normalized) ||
          formattedDate.includes(normalized)
        );
      });
      setFilteredArticles(results);
    }
  }, [searchQuery, articles]);

  const handleEdit = async (article) => {
    try {
      await api.put(`/articles/start-review/${article._id}`);
      navigate("/admin/dashboard", {
        state: { editArticleId: article.uniqueId },
      });
    } catch (err) {
      toast.error("❌ لا يمكنك مراجعة هذا المقال.");
    }
  };

  const canActOn = (article) => {
    if (article.reviewStatus === "approved") return false;
    if (!article.editor) return true;
    return article.editor._id === loggedInUserId;
  };

  const handleApprove = async (id) => {
    try {
      await api.put(`/articles/review-publish/${id}`, { action: "approve" });
      toast.success("✅ تم الموافقة على المقال");
      fetchReviewArticles();
    } catch (err) {
      toast.error("❌ فشل في الموافقة على المقال");
    }
  };

  const handleDecline = async (id) => {
    const reason = window.prompt("يرجى كتابة سبب الرفض:");
    if (!reason) return;
    try {
      await api.put(`/articles/review-publish/${id}`, {
        action: "decline",
        reason,
      });
      toast.info("📌 تم رفض المقال");
      fetchReviewArticles();
    } catch (err) {
      toast.error("❌ فشل في رفض المقال");
    }
  };

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm("⚠️ هل أنت متأكد من حذف هذا المقال بشكل نهائي؟");
    if (!confirmDelete) return;
    try {
      await api.delete(`/articles/${id}`);
      toast.success("🗑️ تم حذف المقال بنجاح");
      fetchReviewArticles();
    } catch (err) {
      toast.error("❌ فشل في حذف المقال");
    }
  };

  if (isWriter) return null;

  return (
    <div className="articles-history-wrapper">
      <div className="search-container">
        <input
          type="text"
          placeholder="🔍 أبحث عن الموضوع"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          className="search-bar-cms"
        />
        <p className="search-info">يمكنك البحث بإستخدام كود الموضوع أو الاسم أو تاريخ النشر</p>
      </div>

      <div className="articles-history-container">
        <h2 className="history-title">طلبات المراجعة والنشر</h2>

        {loading ? (
          <p className="loading-text">⏳ جاري تحميل المقالات...</p>
        ) : (
          <div className="articles-list">
            <div className="article-item">
              <div className="article-info-box">Topic ID</div>
              <div className="article-info-box">العنوان</div>
              <div className="article-info-box">الكاتب</div>
              <div className="article-info-box">المحرر</div>
              <div className="article-info-box">الحالة</div>
              <div className="article-info-box">آخر تعديل</div>
              <div className="article-info-box">الإجراءات</div>
            </div>

            {filteredArticles.length > 0 ? (
              filteredArticles.map((article) => {
                const editorName = article.editor?.fullName || "غير محدد";
                const authorName = article.author?.fullName || "غير معروف";

                return (
                  <div className="article-item" key={article._id}>
                    <div className="article-info-box">{article.uniqueId}</div>
                    <div className="article-info-box">{article.title}</div>
                    <div className="article-info-box">{authorName}</div>
                    <div className="article-info-box">{editorName}</div>
                    <div className="article-info-box">{getStatusLabel(article.reviewStatus)}</div>
                    <div className="article-info-box">{formatDate(article.updatedAt)}</div>
                    <div className="article-actions">
                      <button
                        className="icon-button view"
                        onClick={() => window.open(`/articles/${article.uniqueId}`, "_blank")}
                        title="عرض المقال"
                      >
                        <FaEye />
                      </button>

                      {canActOn(article) && (
                        <>
                          <button
                            className={`icon-button edit ${!canActOn(article) ? "disabled" : ""}`}
                            onClick={() => {
                              if (!canActOn(article)) {
                                toast.info(`📌 هذا المقال قيد المراجعة من قبل: ${article.editor?.fullName || "محرر آخر"}`);
                                return;
                              }
                              handleEdit(article);
                            }}
                            title="تعديل"
                            disabled={!canActOn(article)}
                          >
                            <FaEdit />
                          </button>

                          <button
                            className="icon-button approve"
                            onClick={() => handleApprove(article._id)}
                            title="موافقة"
                          >
                            <FaCheck />
                          </button>
                          <button
                            className="icon-button decline"
                            onClick={() => handleDecline(article._id)}
                            title="رفض"
                          >
                            <FaTimes />
                          </button>
                        </>
                      )}

                      {isSuperAdminOrCEO && (
                        <button
                          className="icon-button delete"
                          onClick={() => handleDelete(article._id)}
                          title="حذف نهائي"
                        >
                          🗑️
                        </button>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <p className="no-data">لا توجد مقالات حالياً.</p>
            )}

            <p className="text-info">
              يمكن للمحرر أو المسؤول مراجعة وتعديل أو حذف المقالات حسب الصلاحيات.
            </p>
          </div>
        )}
      </div>
      <ToastContainer />
    </div>
  );
};

export default ReviewRequests;
