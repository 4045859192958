import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import api from "../utils/axiosConfig";
import { ToastContainer, toast } from "react-toastify";
import closeIcon from "../assets/images/close-icon.svg";
import "react-toastify/dist/ReactToastify.css";
import "./ChangePasswordModal.css";
import { AuthContext } from "../utils/AuthContext";
import { disconnectSocket } from "../utils/socket";

const ChangePasswordModal = ({ isOpen, onClose }) => {
  const { user, admin, clearAuth } = useContext(AuthContext);
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSaveChanges = async () => {
    if (!oldPassword || !newPassword || !confirmPassword) {
      return toast.error("Please fill in all fields.");
    }

    if (newPassword !== confirmPassword) {
      return toast.error("New passwords do not match.");
    }

    setIsLoading(true);
    const endpoint = user ? "/users/me" : admin ? "/admin/me" : null;

    if (!endpoint) {
      toast.error("No user or admin session found.");
      return;
    }

    try {
      const response = await api.put(endpoint, {
        oldPassword,
        newPassword,
      });

      toast.success(response.data.message || "Password updated successfully!", {
        position: "bottom-left",
        autoClose: 2000,
      });

      setTimeout(async () => {
        try {
          disconnectSocket();
          await api.post("/logout");
          clearAuth();

          // Redirect based on previous role
          if (admin) {
            navigate("/admin/login");
          } else {
            navigate("/signin");
          }
        } catch (logoutErr) {
          console.error("Logout after password change failed:", logoutErr);
        } finally {
          onClose(); // close modal regardless
        }
      }, 2000);
    } catch (error) {
      toast.error(error.response?.data?.message || "Error updating password", {
        position: "bottom-left",
        autoClose: 5000,
      });
    } finally {
      setIsLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="cp-modal-overlay">
      <div className="cp-modal-content">
        <div className="cp-close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" />
        </div>
        <div className="cp-password-text">تغيير كلمة المرور</div>
        <div className="cp-password-question">
          هل أنت متأكد من تغيير كلمة المرور؟
        </div>

        <div className="cp-input-field">
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
            placeholder="كلمة السر الحالية"
          />
        </div>

        <div className="cp-input-field">
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            placeholder="كلمة السر الجديدة"
          />
        </div>

        <div className="cp-input-field">
          <input
            type="password"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            placeholder="تأكيد كلمة السر الجديدة"
          />
        </div>

        <div
          className="cp-save-button"
          onClick={handleSaveChanges}
          style={{ cursor: isLoading ? "not-allowed" : "pointer" }}
        >
          {isLoading ? "جاري حفظ التغييرات..." : "حفظ التغييرات"}
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default ChangePasswordModal;
