import React from "react";

import Preloader from "../components/Preloader/Preloader";
import Timer from "../components/Countdown/Timer";
import Optin from "../components/Optin/Optin";

// Import specific styles for the NotFound page
import "./NotFound.css";

const NotFound = () => {
  return (
    <div className="notfound-page">
      <div className="App">
        <div className="container">
          <h1>
            BACKEND IS ON FIRE 🔥
            <br />
          Ahmed Ashraf On TOP 🔝
          </h1>
          <Timer />
          <Optin />
          <Preloader />
        </div>
      </div>
    </div>
  );
};

export default NotFound;
