import React from "react";
import "./SettingsModal.css";

const SettingsModal = ({ isOpen, closeModal, user }) => {
  if (!isOpen) return null;

  return (
    <div className="profile-view-private">
      <div className="div" data-collection-1-mode="dark">
        <div className="overlap">
          <img className="header-BG" src="img/header-BG.png" alt="Header Background" />
          <img className="header-underline" src="img/header-underline.svg" alt="Header Underline" />
          <div className="logo-slogan">
            <img className="logo-main" src="img/logo-main.png" alt="Logo" />
            <img className="encircle-the-world" src="img/encircle-the-world.png" alt="Slogan" />
          </div>
          <div className="change-profile"></div>
        </div>
        <div className="frame">
          <div className="frame-2">
            <div className="text-wrapper">بيانات شخصية</div>
          </div>
          <div className="frame-wrapper">
            <div className="frame-3">
              <div className="frame-4">
                <img className="img" src="img/message-edit.svg" alt="Edit Icon" />
                <div className="text-wrapper-2">{user?.full_name || "محمد رجب ضاحي"}</div>
                <div className="text-wrapper-3">الأسم بالكامل :</div>
              </div>
              <div className="frame-4">
                <img className="img" src="img/message-edit.svg" alt="Edit Icon" />
                <div className="text-wrapper-4">{user?.nickname || "Waterlaw T. Ollivander"}</div>
                <div className="text-wrapper-3">الأسم المستعار :</div>
              </div>
              <div className="frame-4">
                <img className="img" src="img/message-edit.svg" alt="Edit Icon" />
                <div className="text-wrapper-4">{user?.phone_number || "01112222444"}</div>
                <div className="text-wrapper-3">رقم الهاتف :</div>
              </div>
              <div className="frame-5">
                <img className="img" src="img/message-edit.svg" alt="Edit Icon" />
                <div className="text-wrapper-4">{user?.email || "waterlaw.t.ollivand@gmail.com"}</div>
                <div className="text-wrapper-3">البريد الألكتروني :</div>
              </div>
              <div className="frame-4">
                <img className="img" src="img/message-edit.svg" alt="Edit Icon" />
                <div className="text-wrapper-4">{user?.birthday || "20/08/2025"}</div>
                <div className="text-wrapper-3">تاريخ الميلاد :</div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-6">
          <div className="frame-2">
            <img className="img-2" src="img/account-tags.svg" alt="Account Tags" />
            <div className="text-wrapper">الإنجاز</div>
          </div>
          <div className="div-wrapper">
            <p className="p">توب فان : متابع جيد لمنصة ايماجين ويبسايت وحقق أكثر من 1000 تفاعل حتى الأن على الموقع</p>
          </div>
        </div>
        <div className="frame-7">
          <div className="frame-2">
            <div className="text-wrapper">حسابات أخرى</div>
          </div>
          <div className="frame-8">
            <div className="frame-9">
              <div className="frame-4">
                <div className="social-icons-wrapper"><img className="img" src="img/social-icons-6.svg" alt="Google Icon" /></div>
                <div className="text-wrapper-5">Waterlaw.t.ollivander@gmail.com</div>
                <div className="text-wrapper-6">جوجل</div>
              </div>
              <div className="frame-4">
                <div className="social-icons-wrapper"><img className="img" src="img/social-icons.svg" alt="Facebook Icon" /></div>
                <div className="text-wrapper-5">@mohamedragab.eldahy</div>
                <div className="text-wrapper-6">فيسبوك</div>
              </div>
              <div className="frame-4">
                <div className="social-icons-wrapper"><img className="img" src="img/social-icons-4.svg" alt="Discord Icon" /></div>
                <div className="text-wrapper-7">Discord ID : 014ASD41AS2D415ASD12</div>
                <div className="text-wrapper-6">ديسكورد</div>
              </div>
            </div>
          </div>
        </div>
        <div className="frame-10">
          <div className="frame-2">
            <img className="img" src="img/message-edit.svg" alt="Edit Icon" />
            <div className="text-wrapper">وصف شخصي / Bio</div>
          </div>
          <div className="frame-11">
            <p className="text-wrapper-8">
              غالبًا ما يحملنا الخيال إلى عوالم لم تكن موجودة أبدًا. ولكن بدونه، لن نصل إلى أي مكان. - كارل ساجان
            </p>
            <div className="text-wrapper-9">320/320</div>
          </div>
        </div>
        <img className="line" src="img/line-26.svg" alt="Line" />
        <img className="line-2" src="img/line-27.svg" alt="Line" />
        <img className="line-3" src="img/line-28.svg" alt="Line" />
        <div className="frame-12">
          <div className="check-mark">
            <div className="overlap-group">
              <img className="vector" src="img/vector.svg" alt="Check Mark" />
              <div className="ellipse"></div>
            </div>
          </div>
          <div className="text-wrapper-6">إظهار تاريخ الميلاد</div>
        </div>
        <div className="frame-13">
          <div className="check-mark-2"></div>
          <div className="text-wrapper-6">إظهار حساب الفيسبوك</div>
        </div>
        <div className="frame-14">
          <div className="text-wrapper-10">تغيير كلمة المرور</div>
          <img className="img" src="img/key.svg" alt="Key Icon" />
        </div>
        <div className="footer-group-icons">
          <div className="nav-bar-footer">
            <div className="text-wrapper-11">من نحن</div>
            <div className="text-wrapper-11">الأسئلة الأكثر ترددًا</div>
            <div className="text-wrapper-11">دليل الشخصيات</div>
            <div className="text-wrapper-11">تقييمات</div>
            <div className="text-wrapper-11">فيديوهات</div>
            <div className="text-wrapper-11">أخبار</div>
            <div className="text-wrapper-11">مدونات</div>
            <div className="text-wrapper-11">مقالات</div>
          </div>
          <div className="text-wrapper-12">تابعونا على المنصات الآتية</div>
          <p className="text-wrapper-13">© IMGN 2022-2024 . All Rights Reserved</p>
          <div className="social-media-icons">
            <img className="social-icons" src="img/social-icons-8.svg" alt="Social Icon" />
            <img className="social-icons" src="img/image.svg" alt="Social Icon" />
            <img className="social-icons" src="img/social-icons-5.svg" alt="Social Icon" />
            <img className="social-icons" src="img/platform-threads-color-negative.svg" alt="Social Icon" />
            <img className="social-icons" src="img/social-icons-7.svg" alt="Social Icon" />
            <img className="social-icons" src="img/platform-tiktok-color-negative.svg" alt="Social Icon" />
            <img className="social-icons" src="img/social-icons-3.svg" alt="Social Icon" />
            <img className="social-icons" src="img/social-icons-10.svg" alt="Social Icon" />
            <img className="img-2" src="img/frame.svg" alt="Social Icon" />
            <img className="social-icons" src="img/social-icons-9.svg" alt="Social Icon" />
            <img className="social-icons" src="img/social-icons-2.svg" alt="Social Icon" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SettingsModal;
