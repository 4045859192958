import React from "react";
import { Editor } from "@tinymce/tinymce-react";

const TinyMCEEditor = ({ value, onChange }) => {
  return (
    <Editor
      apiKey="e377n6k6jk4zbi7psld9otwcg0i4zw9zdkc5uaww5dy3pec1"
      value={value}
      init={{
        branding: false,
        resize: false,
        height: 300,
        menubar: false,
        elementpath: false,
        statusbar: false,
        forced_root_block: "p", // ✅ Ensures paragraphs keep proper formatting
        plugins: [
          "link", "lists", "charmap", "searchreplace",
          "visualblocks", "wordcount", "code"
        ],
        toolbar: `
          undo redo | bold italic underline strikethrough |
          forecolor backcolor | alignleft aligncenter alignright alignjustify |
          bullist numlist | link | fontsize fontfamily lineheight | charmap searchreplace visualblocks wordcount
        `,
        
        font_family_formats: `
          Noto Sans Arabic=Noto Sans Arabic, sans-serif;
          Tajawal=Tajawal, sans-serif;
          Arial=Arial, sans-serif;
          Courier New=Courier New, monospace;
          Georgia=Georgia, serif;
          Times New Roman=Times New Roman, serif;
          Verdana=Verdana, sans-serif;
        `,
        font_size_formats: "12px 14px 16px 18px 24px 36px 48px 72px",
        line_height_formats: "1.0 1.2 1.4 1.6 1.8 2.0",

        // ✅ Import Google Font & Apply Styling
        content_style: `
          @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+Arabic:wght@100..900&display=swap');
          
          body {
            font-family: 'Noto Sans Arabic', sans-serif;
            font-size: 16px;
            direction: rtl;
            text-align: right;
            padding: 12px;
            margin: 0;
            line-height: 1.4;
          }
          div, p {
            margin: 0;
            padding: 0;
            line-height: 1.4;
          }
          .tox-toolbar, .tox-editor-container {
            background: #F5F5F5 !important;
          }
        `,
      }}
      onEditorChange={onChange}
    />
  );
};

export default TinyMCEEditor;
