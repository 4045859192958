import React, { createContext, useState, useEffect, useCallback } from "react";
import api from "./axiosConfig";
import { jwtDecode } from "jwt-decode";
import { getSocket, initSocket } from "./socket";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";


export const AuthContext = createContext();

const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [isReady, setIsReady] = useState(false);

  const clearAuth = useCallback(() => {
    const socket = getSocket();
    if (socket && socket.connected) {
      socket.disconnect(); // only disconnect on logout
    }
  
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("user");
    localStorage.removeItem("admin");
    localStorage.removeItem("role");
  
    setUser(null);
    setAdmin(null);
    setIsReady(true);
  }, []);
  

  useEffect(() => {
    return () => {
      const socket = getSocket();
      if (socket && socket.connected) {
        socket.disconnect(); // Ensures full cleanup on unmount
      }
    };
  }, []);
  
  
  
  const isTokenValid = useCallback(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) return false;

    try {
      const payload = jwtDecode(token);
      return payload.exp * 1000 > Date.now();
    } catch {
      return false;
    }
  }, []);

  const loadUserData = useCallback(async () => {
    try {
      const storedUser = localStorage.getItem("user");
      const storedAdmin = localStorage.getItem("admin");
  
      if (storedUser) {
        try {
          const res = await api.get("/users/me");
          setUser(res.data);
        } catch (err) {
          if (err.response?.status === 404) {
            console.warn("🛑 User not found in DB. Clearing local storage.");
            localStorage.removeItem("user");
          } else {
            console.warn("❌ Failed to fetch user:", err.message);
          }
          throw err;
        }
      } else if (storedAdmin) {
        try {
          const res = await api.get("/admin/me");
          const normalizedAdmin = {
            ...res.data,
            _id: res.data._id || res.data.id,
            id: res.data.id || res.data._id,
          };
          console.log("✅ Admin loaded:", normalizedAdmin);
          setAdmin(normalizedAdmin);
          
        } catch (err) {
          console.warn("❌ Admin fetch failed, retrying in 1s");
          await new Promise(resolve => setTimeout(resolve, 1000));
          try {
            const resRetry = await api.get("/admin/me");
            const normalizedRetryAdmin = {
              ...resRetry.data,
              _id: resRetry.data._id || resRetry.data.id,
              id: resRetry.data.id || resRetry.data._id,
            };
            console.log("🔁 Retried admin load:", normalizedRetryAdmin);
            setAdmin(normalizedRetryAdmin);

          } catch (err2) {
            if (err2.response?.status === 404) {
              console.warn("🛑 Admin not found in DB. Clearing local storage.");
              localStorage.removeItem("admin");
            } else {
              console.warn("❌ Admin retry failed:", err2.message);
            }
            throw err2;
          }
        }
      } else {
        // Fallback: nothing stored
        if (user || admin) return;
      }
    } catch (err) {
      console.error("AuthContext error fetching user:", err);
      clearAuth();
    } finally {
      setIsReady(true); // Always finalize readiness
    }
  }, [clearAuth, user, admin]);
  
  

  
  useEffect(() => {
    let didInit = false;
  
    const initializeAuth = async () => {
      if (didInit) return;
      didInit = true;
  
      const token = localStorage.getItem("accessToken");
      const isValid = token && isTokenValid();
      if (token) {
        try {
          const payload = jwtDecode(token);
          console.log("🧾 Decoded token payload:", payload);
        } catch (err) {
          console.warn("⚠️ Failed to decode token:", err.message);
        }
      }
      
      if (isValid) {
        await loadUserData();
        const socket = initSocket(token, clearAuth);
      
        socket.on("auth-status", (userInfo) => {
          console.log("📡 Received auth-status:", userInfo);
          if (!userInfo) {
            clearAuth();
          }
        });
      
        socket.on("force-logout", (message) => {
          console.warn("⚠️ Received force logout from server");
          toast.error(message || "تم تسجيل خروجك تلقائيًا.");
          clearAuth();
        });
      
      } else {
        // Guest: connect socket without auth token
        const guestSocket = initSocket(null, clearAuth);
        if (guestSocket) {
          guestSocket.on("auth-status", (userInfo) => {
            console.log("📡 Guest socket auth-status:", userInfo);
          });
        }
        
      }
  
      setIsReady(true);
    };
  
    if (!isReady) {
      initializeAuth();
    }
  
    return () => {
      const socket = getSocket();
      if (socket) {
        socket.off("auth-status");
        // No disconnect here — allow graceful reconnects unless logging out
      }
    };
  }, [isReady, isTokenValid, loadUserData, clearAuth]);
  
  
  
   
  

  return (
    <AuthContext.Provider
      value={{
        user,
        admin,
        isReady,
        refreshUser: loadUserData,
        clearAuth,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
