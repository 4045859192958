import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TinyMCEEditor from "../../../components/TinyMCEEditor";
import GridEditor from "../GridSystem/GridEditor";
import checkmarkIcon from "../../../assets/images/checkmark.png";
import { ReactComponent as FolderAdd } from "../../../assets/images/folder-add.svg";
import api from "../../../utils/axiosConfig"; // Import the Axios instance with interceptors
import { getSocket } from "../../../utils/socket";
import "./Styles/AddArticle.css";

const AddArticle = () => {
  const navigate = useNavigate();
  const [title, setTitle] = useState("");
  const [subTitle, setSubTitle] = useState("");
  const [hero, setHero] = useState("");
  const [description, setDescription] = useState("");
  const [tag, setTag] = useState("");
  const [tags, setTags] = useState([]);
  const [publishStatus, setPublishStatus] = useState("common");
  const [layout, setLayout] = useState([]);
  const [cardImage, setCardImage] = useState(null);
  const [cardImagePreview, setCardImagePreview] = useState(null);
  const [images, setImages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [authorName, setAuthorName] = useState("Author"); // Default placeholder
  const [userRole, setUserRole] = useState("");
  const [closure, setClosure] = useState(""); // State for closure input

  const DRAFT_KEY = "article_draft";

  const hasMeaningfulContent = (draft) => {
    return (
      draft.title.trim() ||
      draft.subTitle.trim() ||
      draft.hero.trim() ||
      draft.description.trim() ||
      draft.tag ||
      draft.layout.length > 0 ||
      draft.cardImagePreview ||
      draft.images.length > 0 ||
      draft.closure.trim()
    );
  };

  useEffect(() => {
    const savedDraft = localStorage.getItem(DRAFT_KEY);
    if (savedDraft) {
      const draft = JSON.parse(savedDraft);
      if (hasMeaningfulContent(draft)) {
        setTitle(draft.title);
        setSubTitle(draft.subTitle);
        setHero(draft.hero);
        setDescription(draft.description);
        setTag(draft.tag);
        setPublishStatus(draft.publishStatus);
        setLayout(draft.layout);
        setCardImagePreview(draft.cardImagePreview);
        setImages(draft.images);
        setClosure(draft.closure);
        toast.info("تم استعادة المسودة من التخزين المحلي.", { autoClose: 3000 });
      } else {
        localStorage.removeItem(DRAFT_KEY);
      }
    }
  }, []);

  useEffect(() => {
    const draft = {
      title,
      subTitle,
      hero,
      description,
      tag,
      publishStatus,
      layout,
      cardImagePreview,
      images,
      closure,
    };

    if (hasMeaningfulContent(draft)) {
      localStorage.setItem(DRAFT_KEY, JSON.stringify(draft));
    } else {
      localStorage.removeItem(DRAFT_KEY);
    }
  }, [title, subTitle, hero, description, tag, publishStatus, layout, cardImagePreview, images, closure]);

  const clearDraft = () => {
    localStorage.removeItem(DRAFT_KEY);
  };

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await api.get(`/tags`);
        setTags(response.data);
      } catch (error) {
        console.error("Error fetching tags:", error);
        toast.error("❌ خطأ في تحميل الفلاتر");
      }
    };
    fetchTags();

    const fetchAdminProfile = async () => {
      try {
        const response = await api.get(`/admin/me`);
        setAuthorName(response.data.full_name || response.data.nickname || "Admin");
        setUserRole(response.data.role);
      } catch (error) {
        console.error("Error fetching admin profile:", error);
      }
    };
    fetchAdminProfile();
  }, []);

  const handleFileChange = (event, setImageFunc, setPreviewFunc) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        setImageFunc(file);
        setPreviewFunc(reader.result);
        toast.success("✅ تم تحميل الصورة بنجاح!", { autoClose: 3000 });
      };
    } else {
      toast.error("❌ فشل تحميل الصورة!", { autoClose: 3000 });
    }
  };

  const handleMultiFileChange = (event) => {
    const files = Array.from(event.target.files);
    setImages(files);
  };

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent || "";
  };

  const handleSubmit = async (reviewStatus) => {
    if (!title.trim() || !subTitle.trim() || !description.trim() || !tag || !cardImage || !closure.trim()) {
      toast.warning("⚠️ يرجى ملء جميع الحقول المطلوبة!");
      return;
    }
    if (!layout.length) {
      toast.warning("⚠️ يرجى إضافة محتوى في تنسيق الشبكة!");
      return;
    }

    setLoading(true);

    const plainDescription = stripHtmlTags(description);

    try {
      let cardImageUrl = "";
      if (cardImage) {
        const cardImageForm = new FormData();
        cardImageForm.append("file", cardImage);
        cardImageForm.append("section", "articles");

        const cardImageResponse = await api.post(`/articles/upload-image`, cardImageForm, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        cardImageUrl = cardImageResponse.data.imageUrl;
      }

      let imageUrls = [];
      if (images.length > 0) {
        for (const image of images) {
          const imageForm = new FormData();
          imageForm.append("file", image);
          imageForm.append("section", "articles");

          const imageResponse = await api.post(`/articles/upload-image`, imageForm, {
            headers: { "Content-Type": "multipart/form-data" },
          });
          imageUrls.push(imageResponse.data.imageUrl);
        }
      }

      const parsedLayout = typeof layout === "string" ? JSON.parse(layout) : layout;
      const normalizedRole = userRole ? userRole.toLowerCase() : "";
      const shouldPublishInstant = reviewStatus === "approved" && ["ceo", "super_admin", "editor"].includes(normalizedRole);

      const formData = new FormData();
      formData.append("title", title);
      formData.append("subTitle", subTitle);
      formData.append("hero", hero);
      formData.append("description", plainDescription);
      formData.append("tag", tag);
      formData.append("publishStatus", publishStatus);
      formData.append("layout", JSON.stringify(parsedLayout));
      formData.append("reviewStatus", reviewStatus);
      formData.append("publishInstant", shouldPublishInstant);
      formData.append("cardImage", cardImageUrl);
      formData.append("subject", "Default Subject");
      formData.append("content", "Default Content");
      formData.append("closure", closure);
      imageUrls.forEach((url) => formData.append("images", url));

      const response = await api.post(`/articles/create`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      // Emit real-time event
      const socket = getSocket();
    if (socket) {
      if (reviewStatus === "draft") {
        socket.emit("article-submitted", response.data.article);
      } else {
        socket.emit("article-created", response.data.article);
      }
    }

      toast.success(
        reviewStatus === "draft"
          ? "✅ تم إرسال المقال للمراجعة!"
          : "✅ تم نشر المقال وسيظهر على صفحة المقالات!",
        {
          toastId: "article-status",
          autoClose: 3000,
          closeOnClick: true,
        }
      );

      setTimeout(() => {
        clearDraft();
        setTitle("");
        setSubTitle("");
        setHero("");
        setDescription("");
        setTag("");
        setPublishStatus("common");
        setLayout([]);
        setCardImage(null);
        setCardImagePreview(null);
        setImages([]);
        setClosure("");
        setLoading(false);
      }, 5000);
    } catch (error) {
      console.error("🚨 Error saving article:", error.response?.data || error);
      if (error.response?.status === 400) {
        toast.error(error.response?.data?.error || "⚠️ يوجد مقال بنفس العنوان أو المحتوى أو الموضوع!");
      } else {
        toast.error("❌ حدث خطأ أثناء حفظ المقال");
      }
      setLoading(false);
    }
  };

  const handleDeleteImage = (index) => {
    if (index === undefined) {
      setCardImage(null);
      setCardImagePreview(null);
    } else {
      setImages((prevImages) => prevImages.filter((_, i) => i !== index));
    }
  };

  return (
    <div className="add-article-container">
      <ToastContainer />
      <div className="settings-guide">
        <p>دليل الإعداد والنشر</p>
        <div className="participation-container">
          <div className="participation-box">
            <span className="participation-label">دور المشاركة</span>
            <div className="participation-input">كاتب ومحرر</div>
          </div>
          <div className="participant-name-box">
            <span className="participant-label">إسم المشارك</span>
            <div className="participant-input">{authorName}</div>
          </div>
        </div>
      </div>

      <div className="category-section">
        <span className="category-label">ينتمي إلى:</span>
        <div className="category-dropdown">
          <select value={tag} onChange={(e) => setTag(e.target.value)} className="article-input-filter">
            <option value="">أختر الفلتر</option>
            {tags.map((t) => (
              <option key={t._id} value={t._id}>{t.tag}</option>
            ))}
          </select>
        </div>
      </div>

      <div className="publish-status-section">
        <p> حالة النشر : </p>
        <div className="publish-options">
          {["common", "latest", "breaking", "exclusive"].map((status) => (
            <div
              key={status}
              className={`check-mark-container ${publishStatus === status ? "selected" : ""}`}
              onClick={() => setPublishStatus(status)}
            >
              <div className="custom-checkmark-icon">
                {publishStatus === status && <img src={checkmarkIcon} alt="✔" />}
              </div>
              <span>{status === "common" ? "شائع Common" : status === "latest" ? "الأحدث latest" : status === "breaking" ? "عاجل breaking" : "حصري Exclusive"}</span>
            </div>
          ))}
        </div>
      </div>
      <br />

      <input type="text" placeholder="عنوان المقال" value={title} onChange={(e) => setTitle(e.target.value)} className="article-input" />
      <input type="text" placeholder="العنوان الفرعي" value={subTitle} onChange={(e) => setSubTitle(e.target.value)} className="article-input" />
      <input type="text" placeholder="رابط الصورة الرئيسية (في حالة عدم وجود رابط يرجى وضع عنوان المقال هنا مرة أخرى)" value={hero} onChange={(e) => setHero(e.target.value)} className="article-input" />
      <br />
      <p>وصف الغلاف:</p>
      <TinyMCEEditor value={description} onChange={setDescription} />

      <div className="dashboard-custom-images">
        <div className="dashboard-custom-images-section">
          <label>صورة الكارت</label>
          {cardImagePreview ? (
            <div className="dashboard-custom-images-item">
              <img src={cardImagePreview} alt="Platform Icon" className="dashboard-custom-images-preview" />
              <button className="dashboard-custom-images-delete" onClick={() => handleDeleteImage()}>
                ✖
              </button>
            </div>
          ) : (
            <label className="dashboard-custom-images-item upload">
              <FolderAdd className="dashboard-custom-images-icon" />
              <input type="file" accept="image/*" onChange={(e) => handleFileChange(e, setCardImage, setCardImagePreview)} style={{ display: "none" }} />
            </label>
          )}
        </div>

        <div className="dashboard-custom-images-section">
          <label>تحميل الصور الإضافية</label>
          <div className="dashboard-custom-images-grid">
            {images.map((image, index) => (
              <div key={index} className="dashboard-custom-images-item">
                <img src={URL.createObjectURL(image)} alt={`Image ${index}`} className="dashboard-custom-images-preview" />
                <button className="dashboard-custom-images-delete" onClick={() => handleDeleteImage(index)}>
                  ✖
                </button>
              </div>
            ))}
            <label className="dashboard-custom-images-item upload">
              <FolderAdd className="dashboard-custom-images-icon" />
              <input type="file" accept="image/*" multiple onChange={handleMultiFileChange} style={{ display: "none" }} />
            </label>
          </div>
        </div>
      </div>

      <GridEditor layout={layout} setLayout={setLayout} />

      <input type="text" placeholder="الخاتمة" value={closure} onChange={(e) => setClosure(e.target.value)} className="article-input" />

      <div className="article-buttons">
        <button className="review-button" onClick={() => handleSubmit("draft")} disabled={loading}>
          📩 إرسال للمراجعة
        </button>
        {/* Show the "Publish" button only for Admin, CEO, or Editor */}
        {["ceo", "super_admin", "editor"].includes(userRole?.toLowerCase()) && (
          <button className="publish-button" onClick={() => handleSubmit("approved")} disabled={loading}>
            🚀 نشر الموضوع
          </button>
        )}
      </div>

      {loading && (
        <div className="loading-container">
          <div className="loading-spinner"></div>
          <p className="loading-text">⏳ جاري التحميل...</p>
        </div>
      )}
    </div>
  );
};

export default AddArticle;
