// src/components/HeroSection2.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ReactComponent as FolderAdd } from "../../assets/images/folder-add.svg";
import { ReactComponent as ArchiveTick } from "../../assets/images/archivetick.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MAX_CHARACTERS = 10;

const HeroSection2 = () => {
  const [heroData, setHeroData] = useState({
    title: "",
    showCarousel: false,
    sections: [],
    carouselCharacters: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero2`);
        console.log("Fetched Hero Data: ", response.data);  // Log the data for debugging
        setHeroData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("❌ Error fetching Hero Section 2:", error);
        setLoading(false);
        toast.error("❌ Failed to fetch Hero Section 2 data");
      }
    };
    fetchHeroData();
  }, []);
  
  

  const handleTextChange = (index, value) => {
    const updatedSections = [...heroData.sections];
    updatedSections[index].label = value;
    setHeroData({ ...heroData, sections: updatedSections });
  };

  const handleSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero2`, heroData);
      toast.success("✅ Changes saved successfully!");
    } catch (error) {
      console.error("❌ Save error:", error);
      toast.error("❌ Failed to save changes!");
    }
  };

  if (loading) return <p>⏳ جاري التحميل...</p>;
  if (!heroData) return <p>❌ لا يوجد بيانات لهذا القسم.</p>;

  return (
    <div className="dashboard-content">
      <h2 className="dashboard-content-title">{heroData.title}</h2>

      {heroData.sections.map((section, index) => (
        <div key={index}>
          {section.type === "subtitle" ? (
            <h3 className="dashboard-content-subtitle">{section.label}</h3>
          ) : (
            <input
              className="dashboard-content-input"
              value={section.label}
              onChange={(e) => handleTextChange(index, e.target.value)}
            />
          )}
          
        </div>
        
      ))}
      <p className="dashboard-character-info">
      تصنيف كروت المواضيع يكون بشكل تلقائي حسب إستعمال المستخدم للفلتر الموجود في الصفحة الرئيسية
          </p>

      <button className="dashboard-save-button" onClick={handleSave}>
        <ArchiveTick className="dashboard-icon save-icon" />
        حفظ التغييرات والنشر
      </button>

      <ToastContainer />
    </div>
  );
};

export default HeroSection2;
