import React, { useState, useEffect , useContext} from "react";
import { Link, useNavigate } from "react-router-dom"; // Import Link and useNavigate for routing
import { ReactComponent as BackgroundSVG } from "../assets/images/signingrp.svg"; 
import lightlogo from "../assets/images/lightlogo.svg"; // Import the logo
import axios from "axios"; // Import axios for making HTTP requests
import { toast } from "react-toastify"; // Import toast for notifications
import "react-toastify/dist/ReactToastify.css"; // Import toast CSS
import "./SignIn.css";
import { AuthContext } from "../utils/AuthContext"; // Adjust the path as needed
import { initSocket } from "../utils/socket";
function SignIn() {
  const [email, setEmail] = useState(""); // State to hold email input
  const [password, setPassword] = useState(""); // State to hold password input
  const [isLoading, setIsLoading] = useState(false); // For managing loading state
  const navigate = useNavigate(); // To navigate to home page after successful login
  const { refreshUser } = useContext(AuthContext);

  // Check if the user is already logged in and redirect to home if true
  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/"); // Redirect to home if user is already logged in
    }
  }, [navigate]);

const handleSubmit = async (e) => {
  e.preventDefault();
  setIsLoading(true);

  try {
    const response = await axios.post(
      `${process.env.REACT_APP_IMGNBACKEND}/users/login`,
      { email, password },
      { headers: { 'Content-Type': 'application/json' }, withCredentials: true }
    );
    
    const data = response.data;

    if (response.status === 200) {
      localStorage.setItem("accessToken", data.accessToken);
      localStorage.setItem("refreshToken", data.refreshToken);
      localStorage.setItem("user", JSON.stringify(data.user));
      localStorage.setItem("role", "user"); // Use the same key as admin role

      await refreshUser();

      const socket = initSocket(data.accessToken);
      socket.connect(); // 🔌 connect manually
      
      navigate("/");

    }
  } catch (error) {
    setIsLoading(false);
    toast.error(error.response?.data?.message || "Login failed");
  }
};

  
  // Function to handle refreshing the token if expired
  const refreshAccessToken = async () => {
    const refreshToken = localStorage.getItem("refreshToken");
    if (!refreshToken) {
      console.error("No refresh token found.");
      return;
    }

    try {
      const response = await axios.post(`${process.env.REACT_APP_IMGNBACKEND}/users/refresh-token`, {
        refreshToken
      });

      const { accessToken } = response.data;
      localStorage.setItem("accessToken", accessToken); // Update access token in localStorage
    } catch (error) {
      console.error("Error refreshing access token:", error);
      toast.error("Failed to refresh token");
    }
  };

  // Handle automatic token refresh on page load (optional based on session)
  useEffect(() => {
    if (localStorage.getItem("accessToken")) {
      refreshAccessToken();
    }
  }, []);

  return (
    <div className="sign-in-wrapper">
      {/* SVG Background */}
      <BackgroundSVG className="background-svg" />

      {/* Sign-in Container */}
      <div className="sign-in-container">
        {/* Left Section */}
        <div className="sign-in-left">
          <h1> ! كن متخيلًا</h1>
          <p>قم بتسجيل الدخول أو إنشاء حساب جديد لبدء رحلتك في عوالم الخيال ...</p>
        </div>

        {/* Right Section */}
        <div className="sign-in-right">
          <img src={lightlogo} alt="IMGN Logo" className="sign-in-logo" />
          
          {/* Login Form */}
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="input-field"
              placeholder="Username, E-mail, or Imaginer ID"
              value={email}
              onChange={(e) => setEmail(e.target.value)} // Set email state on change
            />
            <input
              type="password"
              className="input-field"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)} // Set password state on change
            />
            <button type="submit" className="primary-button-signin" disabled={isLoading}>
              {isLoading ? "Loading..." : "Login"}
            </button>
          </form>

          {/* Forgot Password Link */}
          <Link to="#" className="forgot-password">
            Forgot Password?
          </Link>

          {/* Divider */}
          <div className="divider">
            <span>Or</span>
          </div>

          {/* Social Buttons */}
          <div className="social-buttons">
            <button className="social-button google">G</button>
            <button className="social-button facebook">F</button>
            <button className="social-button discord">D</button>
          </div>

          {/* Register Link */}
          <p className="register-link">
            Don’t have an account? 
            <Link to="/signup" className="register-now">
              Register Now
            </Link>
          </p>
        </div>
      </div>
    </div>
  );
}

export default SignIn;
