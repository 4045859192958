import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as ArchiveTick } from "../../assets/images/archivetick.svg"; // Save button icon

const HeroSection6 = () => {
  const [heroData, setHeroData] = useState({
    title: "",
    sections: [],
  });

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero6`);
        setHeroData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("❌ Error fetching Hero Section 6:", error);
        setLoading(false);
        toast.error("❌ Failed to fetch Hero Section 6 data");
      }
    };

    fetchHeroData();
  }, []);

  const handleTitleChange = (value) => {
    setHeroData({ ...heroData, title: value });
  };

  const handleTextChange = (index, value) => {
    const updatedSections = [...heroData.sections];
    updatedSections[index].label = value;
    setHeroData({ ...heroData, sections: updatedSections });
  };
  
  const resizeTextArea = (e) => {
    e.target.style.height = "auto"; // Reset height to auto to shrink if needed
    e.target.style.height = `${e.target.scrollHeight}px`; // Set height to match content
  };
  
  const handleSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero6`, heroData);
      toast.success("✅ Changes saved successfully!");
    } catch (error) {
      console.error("❌ Save error:", error);
      toast.error("❌ Failed to save changes!");
    }
  };

  if (loading) return <p>⏳ جاري التحميل...</p>;
  if (!heroData) return <p>❌ لا يوجد بيانات لهذا القسم.</p>;

  return (
    <div className="dashboard-content">
      {/* Editable Title */}
      <input
        className="dashboard-content-title-input"
        value={heroData.title}
        onChange={(e) => handleTitleChange(e.target.value)}
      />

      {/* Editable "من نحن" Section */}
      <input
        className="dashboard-content-input"
        value={heroData.sections[0]?.label || ""}
        onChange={(e) => handleTextChange(0, e.target.value)}
      />

      {/* Editable Description */}
      <div className="dashboard-content-description-container">
        <h3 className="dashboard-content-subtitle">الوصف المختصر للمنصة</h3>
              <textarea
        className="dashboard-content-description"
        value={heroData.sections[1]?.label || ""}
        onChange={(e) => handleTextChange(1, e.target.value)}
        onInput={resizeTextArea} // 🔥 This makes height dynamic
        />

      </div>

      {/* Save Button */}
      <button className="dashboard-save-button" onClick={handleSave}>
        <ArchiveTick className="dashboard-icon save-icon" />
        حفظ التغييرات والنشر
      </button>

      <ToastContainer />
    </div>
  );
};

export default HeroSection6;
