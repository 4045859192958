import React, { useEffect, useState } from "react";
import api from "../../../utils/axiosConfig";
import { getSocket } from "../../../utils/socket";
import "./AdminsList.css";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import "dayjs/locale/ar";

dayjs.extend(relativeTime);
dayjs.locale("ar");

const AdminsList = () => {
  const [admins, setAdmins] = useState([]);
  const [onlineIds, setOnlineIds] = useState([]);
  const [search, setSearch] = useState("");
  const [filterRole, setFilterRole] = useState("all");
  const [isCeo, setIsCeo] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingToggleId, setLoadingToggleId] = useState(null);
  const currentAdmin = JSON.parse(localStorage.getItem("admin"));
  const isSelf = (adminId) => {
    try {
      const a = adminId?.toString();
      const b = currentAdmin?._id?.toString() || currentAdmin?.id?.toString(); // Support both _id and id
  
      const match = a === b;
  
      if (process.env.NODE_ENV !== "production") {
        console.log("Comparing admin ID:", a, "with currentAdmin ID:", b, "Match?", match);
      }
  
      return match;
    } catch {
      return false;
    }
  };
  

  
  useEffect(() => {
    const socket = getSocket();
    const interval = setInterval(() => {
      if (socket?.connected) {
        socket.emit("heartbeat");
      }
    }, 60000); // Every 1 minute
  
    return () => clearInterval(interval);
  }, []);
  

  useEffect(() => {
    const fetchAdmins = async () => {
      try {
        const res = await api.get("/admin/all");
        setAdmins(res.data);
  
        const role = currentAdmin?.role?.toLowerCase();
        if (role === "ceo") {
          setIsCeo(true);
          console.log("🟣 You are the CEO");
        } else {
          console.log("🟠 You are not the CEO, role:", role);
        }
  
      } catch (err) {
        console.error("❌ Failed to fetch admins", err);
        toast.error("تعذر تحميل قائمة الأدمنز");
      } finally {
        setLoading(false);
      }
    };
  
    fetchAdmins();
  
    const socket = getSocket();
    if (!socket) {
      console.warn("❌ No active socket found in AdminsList");
      return;
    }
  
    if (socket.connected) {
      socket.emit("request-online-admins");
    } else {
      socket.once("connect", () => {
        socket.emit("request-online-admins");
      });
    }
  
    const handleStatus = (ids) => setOnlineIds(ids);
    socket.on("admin-online-status", handleStatus);
  
    // ✅ NEW: Update specific admin's lastSeen in real-time
    const handleLastSeenUpdate = (data) => {
      setAdmins((prev) =>
        prev.map((admin) =>
          admin._id === data._id
            ? { ...admin, lastSeen: data.lastSeen }
            : admin
        )
      );
    };
    socket.on("admin-last-seen-updated", handleLastSeenUpdate);
  
    // Refresh online status when tab is visible
    const handleVisibility = () => {
      if (document.visibilityState === "visible" && socket.connected) {
        socket.emit("request-online-admins");
      }
    };
    document.addEventListener("visibilitychange", handleVisibility);
  
    return () => {
      socket.off("admin-online-status", handleStatus);
      socket.off("admin-last-seen-updated", handleLastSeenUpdate); // ✅ Cleanup new listener
      document.removeEventListener("visibilitychange", handleVisibility);
    };
  }, []);
  

  const handleRoleChange = async (id, newRole) => {
    if (currentAdmin?._id === id) {
      return alert("🚫 لا يمكنك تعديل دورك الخاص.");
    }

    try {
      await api.put(`/admin/update-role/${id}`, { role: newRole });
      setAdmins((prev) =>
        prev.map((admin) =>
          admin._id === id ? { ...admin, role: newRole } : admin
        )
      );
      toast.success("✅ تم تحديث الدور بنجاح");
    } catch (err) {
      console.error("❌ Failed to update role", err);
      toast.error("فشل في تحديث الدور");
    }
  };

  const handleToggleActive = async (id) => {
    if (currentAdmin?._id === id) {
      return alert("🚫 لا يمكنك تعطيل نفسك.");
    }
  
    setLoadingToggleId(id); // Start loading for this admin
    try {
      const res = await api.patch(`/admin/toggle-active/${id}`);
  
      setAdmins((prev) =>
        prev.map((admin) =>
          admin._id === id ? { ...admin, isActive: res.data.isActive } : admin
        )
      );
  
      toast.success(`تم ${res.data.isActive ? "تفعيل" : "تعطيل"} الحساب بنجاح`);
    } catch (err) {
      console.error("❌ Failed to toggle status", err);
      toast.error("فشل في تغيير الحالة");
    } finally {
      setLoadingToggleId(null); // Reset loading state
    }
  };
  
  

  const filteredAdmins = admins.filter((admin) => {
    const matchSearch =
      admin.fullName.toLowerCase().includes(search.toLowerCase()) ||
      admin.role.toLowerCase().includes(search.toLowerCase());
    const matchRole =
      filterRole === "all" || admin.role.toLowerCase() === filterRole;
    return matchSearch && matchRole;
  });

    // ✅ Find current admin from filtered list
    const currentAdminObj = filteredAdmins.find((admin) => isSelf(admin._id));

    // ✅ Sort other admins by online status then name
    const otherAdmins = filteredAdmins
    .filter((admin) => !isSelf(admin._id))
    .sort((a, b) => {
        const aOnline = onlineIds.includes(a._id);
        const bOnline = onlineIds.includes(b._id);

        if (aOnline !== bOnline) return bOnline - aOnline; // Online first
        return a.fullName.localeCompare(b.fullName, "ar");
    });

    // ✅ Final list: self always first, then others
    const sortedAdmins = currentAdminObj
    ? [currentAdminObj, ...otherAdmins]
    : otherAdmins;


  const showActionsColumn = sortedAdmins.some(
    (admin) => admin._id !== currentAdmin?._id
  );

  return (
    <div className="admins-list-container">
  <h2>قائمة الأدمنز</h2>

  <div className="admin-toolbar">
    <input
      type="text"
      placeholder="ابحث عن أدمن بالاسم أو الدور..."
      value={search}
      onChange={(e) => setSearch(e.target.value)}
    />

    <select
      className="role-filter"
      value={filterRole}
      onChange={(e) => setFilterRole(e.target.value)}
    >
      <option value="all">كل الأدوار</option>
      <option value="ceo">CEO</option>
      <option value="super_admin">Admin</option>
      <option value="editor">Editor</option>
      <option value="writer">Writer</option>
    </select>
  </div>

  {loading ? (
    <div className="loading">جاري تحميل البيانات...</div>
  ) : sortedAdmins.length === 0 ? (
    <div className="no-results">لا يوجد أدمنز تطابق البحث أو التصفية.</div>
  ) : (
    <table className="admins-table">
      <thead>
        <tr>
          <th>الاسم</th>
          <th>الصورة</th>
          <th>الدور</th>
          <th>الحالة</th>
          {isCeo && showActionsColumn && <th>إجراءات</th>}
        </tr>
      </thead>
      <tbody>
        {sortedAdmins.map((admin) => (
          <tr key={admin._id} className={isSelf(admin._id) ? "self-row" : ""}>
            <td>
                {admin.fullName}
                {isSelf(admin._id) && (
                    <span className="self-badge" title="هذا أنت"> (أنت) </span>
                )}
                </td>


            <td>
              <img
                src={admin.profile_picture || "/assets/default-avatar.png"}
                alt="Admin Avatar"
                className="admin-avatar"
              />
            </td>

            <td>
              {isSelf(admin._id) ? (
                <span
                  className={`role-badge ${admin.role.toLowerCase()} self-action-note`}
                  title="لا يمكن تعديل دورك الخاص"
                >
                  {admin.role}
                </span>
              ) : isCeo ? (
                <select
                  className="inline-role-dropdown"
                  value={admin.role}
                  onChange={(e) =>
                    handleRoleChange(admin._id, e.target.value)
                  }
                >
                  <option value="ceo">CEO</option>
                  <option value="super_admin">Admin</option>
                  <option value="editor">Editor</option>
                  <option value="writer">Writer</option>
                </select>
              ) : (
                <span className={`role-badge ${admin.role.toLowerCase()}`}>
                  {admin.role}
                </span>
              )}
            </td>

            <td className="admin-status">
              <span
                className={`dot ${
                  onlineIds.includes(admin._id) ? "online" : "offline"
                }`}
                title={
                  onlineIds.includes(admin._id) ? "متصل" : "غير متصل"
                }
              ></span>
              <span className="status-label">
                {onlineIds.includes(admin._id) ? "متصل" : "غير متصل"}
              </span>
              {!onlineIds.includes(admin._id) && admin.lastSeen && (
                <div
                  className="last-seen"
                  title={dayjs(admin.lastSeen).format("YYYY-MM-DD HH:mm")}
                >
                  آخر ظهور: {dayjs(admin.lastSeen).fromNow()}
                </div>
              )}
            </td>

            {isCeo && (
              <td>
                {isSelf(admin._id) ? (
                  <span
                    className="self-action-note"
                    title="لا يمكنك تعطيل حسابك"
                  >
                    —
                  </span>
                ) : (
                  <button
                    className="toggle-button"
                    disabled={loadingToggleId === admin._id}
                    onClick={() => handleToggleActive(admin._id)}
                  >
                    {admin.isActive ? "تعطيل" : "تفعيل"}
                  </button>
                )}
              </td>
            )}
          </tr>
        ))}
      </tbody>
    </table>
  )}
</div>

  );
};

export default AdminsList;
