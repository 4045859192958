import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import api from "../../../utils/axiosConfig"; // Your Axios instance for API calls
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./ArticleDetail.css";
import Header from "../../../components/PageHeader";
import Footer from "../../../components/Footer";
import { format } from "date-fns"; // Import date-fns for formatting

const ArticleDetail = () => {
  const [article, setArticle] = useState(null);
  const [loading, setLoading] = useState(true);
  const { uniqueId } = useParams(); // Use uniqueId here

  useEffect(() => {
    const fetchArticle = async () => {
      try {
        setLoading(true);
        const response = await api.get(`/articles/unique/${uniqueId}`, {
          params: { populate: "author,editor,tag" },
        });
        setArticle(response.data); // Set the article data
        setLoading(false);
      } catch (error) {
        setLoading(false);
        toast.error("❌ Error fetching article data!");
      }
    };

    fetchArticle();
  }, [uniqueId]);

  if (loading) {
    return <p>⏳ Loading article...</p>;
  }

  if (!article) {
    return <p>❌ Article not found!</p>;
  }

  const {
    title,
    subTitle,
    hero,
    description,
    tag,
    publishStatus,
    layout,
    closure,
    cardImage,
    images,
    author,
    editor,
    createdAt,
  } = article;

  // Format the createdAt date
  const formattedDate = format(new Date(createdAt), "dd MMM, yyyy, HH:mm"); // "12 Oct, 2024, 18:45"

  return (
    <div className="article-detail-container">
      <ToastContainer />
      <Header />

      <main>
        <section className="article-content">
          <h1 className="article-title">{title}</h1>

          {/* Roles and Meta Section */}
          <div className="roles-layout">
            <div className="authors">
              <div>كتابة و تحضير : {author?.fullName}</div>
              <div>تحرير ومراجعه : {editor?.fullName}</div>
            </div>
            <div>{formattedDate}</div> {/* Display the formatted createdAt date */}
          </div>

          {/* Line after roles */}
          <div className="line-separator"></div>

          {/* Image Section */}
          <div className="article-images">
            {images.length === 1 ? (
              <img
                className="article-image single-image"
                src={images[0]}
                alt="Article"
              />
            ) : (
              images.slice(0, 2).map((image, index) => (
                <img
                  key={index}
                  className="article-image two-images"
                  src={image}
                  alt={`Image ${index + 1}`}
                />
              ))
            )}
          </div>

          {/* Line after images */}
          <div className="line-separator"></div>

          {/* Layout Section */}
          {layout &&
            layout.length > 0 &&
            layout.map((section, index) => (
              <div
                key={index}
                className={`article-layout ${
                  section.mode === "2-col" ? "two-column" : "one-column"
                }`}
              >
                {section.cells.map((cell, cellIndex) => (
                  <div
                    key={cellIndex}
                    className={`layout-cell ${
                      section.mode === "2-col" ? "two-column-cell" : "one-column-cell"
                    }`}
                  >
                    {cell.type === "text" ? (
                      <div
                        className="article-layout-text"
                        dangerouslySetInnerHTML={{ __html: cell.content }}
                      />
                    ) : (
                      <div className="article-layout-image">
                        <img src={cell.content} alt={`Layout Image ${cellIndex}`} />
                      </div>
                    )}
                  </div>
                ))}
              </div>
            ))}

          {/* Line after layout */}
          <div className="layout-line"></div>

          {/* Closure */}
          <div className="article-closure">{closure}</div>

          {/* Line after closure */}
          <div className="closure-line"></div>
        </section>
      </main>

      <Footer />
    </div>
  );
};

export default ArticleDetail;