import React from "react";
import "./RatingSection.css";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

// Mock data for ratings
const ratings = [
  {
    id: 1,
    title: "علاء الدين 2019",
    description:
      "يحاول جعفر وزير السلطان اغراء السلطان للحصول على المصباح السحري الذي يوجد فيه الجني...",
    backgroundImage: "/aladdin.png",
    posterImage: "/aladdin-poster.png",
    genres: ["خيال", "موسيقى", "رومانسية"],
    userScore: 50, // User score as a percentage (0-100)
    criticsScore: 87, // Change to a percentage value (0-100)
  },
  
];

function RatingSection() {
  return (
    <section className="rating-section">
      <h3 className="rating-section-title">التقييمات</h3>
      <p className="rating-section-subtitle">
        تجد هنا تقييمات الاعمال ذات الصلة بالفانتازيا ... كتب, أفلام, مسلسلات
        .... والكثير من التصنيفات الأخرى
      </p>

      <div className="rating-card-container">
        {ratings.map((rating) => (
          <div
            key={rating.id}
            className="rating-card"
            style={{ backgroundImage: `url(${rating.backgroundImage})` }}
          >
            <div className="rating-card-overlay"></div>

            <div className="rating-container">
              {/* Poster Image */}
              <div className="rating-poster">
                <img
                  src={rating.posterImage}
                  alt={rating.title}
                  className="rating-card-image"
                />
              </div>

              {/* Details */}
              <div className="rating-details">
                <h3 className="rating-title">{rating.title}</h3>
                <p className="rating-description">{rating.description}</p>

                {/* Genre Tags */}
                <div className="rating-genres">
                  {rating.genres.map((genre, index) => (
                    <span key={index} className="rating-genre">
                      {genre}
                    </span>
                  ))}
                </div>

                <div className="rating-scores">
                  <div className="rating-score-item">
                    <span className="rating-score-label">تقييم المتابعين</span>
                    <div
                      style={{
                        width: "48px",
                        height: "48px",
                      }}
                    >
                      <CircularProgressbar
                        value={rating.userScore}
                        text={`${(rating.userScore / 10).toFixed(1)}`}
                        styles={buildStyles({
                          textColor: "#FFF",
                          pathColor: "#4FD9FC",
                          trailColor: "rgba(79, 217, 252, 0.2)",
                          textSize: "30px",
                        })}
                      />
                    </div>
                  </div>
                  <div className="rating-score-item">
                    <span className="rating-score-label">
                    ايماجينوميتر (تقييم تخيل)
                    </span>
                    <div
                      style={{
                        width: "48px",
                        height: "48px",
                      }}
                    >
                      <CircularProgressbar
                        value={rating.criticsScore}
                        text={`${(rating.criticsScore / 10).toFixed(1)}`}
                        styles={buildStyles({
                            textColor: "#FFF",
                            pathColor: "#4FD9FC",
                            trailColor: "rgba(79, 217, 252, 0.2)",
                            textSize: "30px",
                          })}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Divider Line */}
            <hr className="rating-divider" />

            {/* Rating Scores and Actions */}
            <div className="rating-bottom-section">
              <div className="rating-actions">
                <button className="rating-button">
                  معلومات أخرى
                  <img src="/info.svg" alt="Info" className="button-icon" />
                </button>
                <button className="rating-button">
                  أترك تقييمك
                  <img src="/rating.svg" alt="Rate" className="button-icon" />
                </button>
                <button className="rating-button">
                  مشاركة العمل
                  <img src="/share.svg" alt="Share" className="button-icon" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="character-actions">
        <button className="character-guide">إنتقل إلى التقييمات</button>
      </div>
    </section>
  );
}

export default RatingSection;
