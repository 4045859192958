import React from "react";
import headerlogo from "../assets/images/Settings-BG.png"; // Ensure the correct path for your logo
import logo from "../assets/images/Logo (Main).png"; // Ensure the correct path for your logo

const Header = () => {
  return (
    <div className="settings-header">
      <div className="header-background">
        <img src={headerlogo} alt="Header Background" />
      </div>
      <div className="header-logo-container">
        <img src={logo} alt="Logo" className="header-logo" />
        <span className="header-text">Encircle The World</span>
      </div>
      <div className="header-line"></div>
    </div>
  );
};

export default Header;
