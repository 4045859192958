import React from "react";
import HeroSection1 from "./HeroSection1";
import HeroSection2 from "./HeroSection2";
import HeroSection3 from "./HeroSection3";
import HeroSection6 from "./HeroSection6";
import SocialSection from "./SocialSection";
import AdminsList from "./admin-list/AdminsList"; 

// 🛠️ Import Articles CMS Components
import ArticlesFilters from "./articles-cms/ArticlesFilters";
import AddArticle from "./articles-cms/AddArticle";
import EditArticle from "./articles-cms/EditArticle";
import ArticlesHistory from "./articles-cms/ArticlesHistory";
import ReviewRequests from "./articles-cms/ReviewRequests";

import "./Styles/ContentArea.css";

const ContentArea = ({ selectedSidebarItem }) => {

  return (
    <div className="dashboard-content">
      {selectedSidebarItem === "القسم الأول Hero 1 العنوان الرئيسي" && <HeroSection1 />}
      {selectedSidebarItem === "القسم الثاني Hero 2 مقالات / مدونات / أخبار" && <HeroSection2 />}
      {selectedSidebarItem === "القسم الثالث Hero 3 شخصيات مهمة" && <HeroSection3 />}
      {selectedSidebarItem === "القسم السادس Hero 6 من نحن" && <HeroSection6 />}
      {selectedSidebarItem === "قنوات السوشيال ميديا" && <SocialSection />}

      {/* 📌 Articles CMS Sections */}
      {selectedSidebarItem === "فلاتر قائمة عرض المقالات" && <ArticlesFilters />}
      {selectedSidebarItem === "إضافة مقال جديد" && <AddArticle />}
      {selectedSidebarItem === "تعديل مقال سابق" && <EditArticle />}
      {selectedSidebarItem === "سجل المقالات السابقة" && <ArticlesHistory />}
      {selectedSidebarItem === "طلبات المراجعة والنشر" && <ReviewRequests />}
      {selectedSidebarItem === "قائمة الادمنز" && <AdminsList/>}

    </div>
  );
};

export default ContentArea;
