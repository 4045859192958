import React, { useState, useContext} from "react";
import { useNavigate } from "react-router-dom";
import { ReactComponent as BackgroundSVG } from "../../assets/images/signingrp.svg";
import lightlogo from "../../assets/images/lightlogo.svg";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./signin.css";
import { AuthContext } from "../../utils/AuthContext"; // adjust path as needed
import { initSocket } from "../../utils/socket";
function SignIn() {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { refreshUser } = useContext(AuthContext);

  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IMGNBACKEND}/admin/login`,
        { email, password }
      );
  
      const { admin, accessToken, refreshToken } = response.data;
  
      if (response.status === 200) {
        const normalizedAdmin = {
          ...admin,
          _id: admin._id || admin.id,
          id: admin.id || admin._id,
        };
  
        localStorage.setItem("admin", JSON.stringify(normalizedAdmin));
        localStorage.setItem("accessToken", accessToken);
        localStorage.setItem("refreshToken", refreshToken);
        localStorage.setItem("role", admin.role?.toLowerCase());
  
        console.log("✅ Admin login success:", normalizedAdmin);
        console.log("🧪 Token:", accessToken);
  
        toast.success("Login successful!");
  
        await refreshUser();
        initSocket(accessToken);
        navigate("/admin/dashboard");
      }
  
    } catch (error) {
      console.error("🚨 Error during login:", error);
      if (error.response) {
        const errorMessage = error.response.data?.message || "Invalid email or password.";
        toast.error(errorMessage);
      } else if (error.request) {
        toast.error("Network error. Please check your connection.");
      } else {
        toast.error("Something went wrong. Please try again.");
      }
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div className="sign-in-wrapper">
      {/* Background SVG */}
      <BackgroundSVG className="background-svg" />

      <div className="sign-in-container">
        <div className="sign-in-left">
          <h1> ! كن متخيلًا</h1>
          <p>قم بتسجيل الدخول للوصول إلى لوحة الإدارة الخاصة بك.</p>
        </div>

        <div className="sign-in-right">
          <img src={lightlogo} alt="IMGN Logo" className="sign-in-logo" />

          <form onSubmit={handleSubmit}>
            <input
              type="email"
              className="input-field"
              placeholder="البريد الإلكتروني"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
            <input
              type="password"
              className="input-field"
              placeholder="كلمة المرور"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit" className="primary-button-signin" disabled={isLoading}>
              {isLoading ? "جاري التحميل..." : "دخول"}
            </button>
          </form>
        </div>
      </div>
    </div>
  );
}


export default SignIn;