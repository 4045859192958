import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./SideMenu.css";

import api from "../utils/axiosConfig"; 
import { ReactComponent as NewsIcon } from "../assets/images/NewsIcon.svg";
import { ReactComponent as ArticlesIcon } from "../assets/images/ArticlesIcon.svg";
import { ReactComponent as BlogsIcon } from "../assets/images/BlogsIcon.svg";
import { ReactComponent as ReviewsIcon } from "../assets/images/ReviewsIcon.svg";
import { ReactComponent as VideosIcon } from "../assets/images/VideosIcon.svg";
import { ReactComponent as CharactersIcon } from "../assets/images/CharactersIcon.svg";
import { ReactComponent as FAQIcon } from "../assets/images/FAQIcon.svg";
import { ReactComponent as AboutIcon } from "../assets/images/AboutIcon.svg";
import { ReactComponent as SettingsIcon } from "../assets/images/SettingsIcon.svg";
import { ReactComponent as LogoutIcon } from "../assets/images/LogoutIcon.svg";
import { ReactComponent as OpenMenuBTN } from "../assets/images/OpenMenuBTN.svg";
import { ReactComponent as CloseMenuBTN } from "../assets/images/CloseMenuBTN.svg";
import { ReactComponent as Logo } from "../assets/images/dark-logo.svg";
import { useTheme } from "./ThemeContext";
import MoonIcon from "../assets/images/moon-icon.svg";
import SunIcon from "../assets/images/sun-icon.png";
import defaultpic from "../assets/images/def-pic.png";
import SettingsModal from "./SettingsModal";  // Settings Modal component
import "./SideMenu.css";
import { useContext } from "react";
import { AuthContext } from "../utils/AuthContext";
import { getSocket } from "../utils/socket";
import { disconnectSocket } from "../utils/socket";


function SideMenu() {
  const { isDarkMode, toggleTheme } = useTheme(); // Use ThemeContext
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const navigate = useNavigate();

  // Fetch user and admin data from the backend if available
  useEffect(() => {
    const token = localStorage.getItem("accessToken");

    if (token) {
      const fetchUserData = async () => {
        try {
          // Check if the user is an admin, if so, fetch admin data
          if (!localStorage.getItem("admin")) {
            const userResponse = await api.get("/users/me"); // Use the configured Axios instance
            setUser(userResponse.data);
          } else {
            // If it's an admin, fetch admin data
            const adminResponse = await api.get("/admin/me"); // Use the configured Axios instance
            setAdmin(adminResponse.data);
          }
        } catch (err) {
          console.error("Error fetching data:", err);
        }
      };

      fetchUserData();
    } else {
      return
    }
  }, []); // Runs once when the component mounts
  
  




  const closeSettings = () => {
    setIsSettingsOpen(false);
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const { clearAuth } = useContext(AuthContext);

  const handleLogout = async () => {
    try {
      disconnectSocket();
  
      await api.post("/logout"); // Server clears cookies if applicable
      clearAuth(); // Clear context + localStorage
      navigate("/signin");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };
  
  
  
  // If no user or admin, return null so the menu doesn't render
  if (!user && !admin) {
    return null;
  }

  
  return (
    <div>
      {/* Full Side Menu */}
      <div className={`side-menu ${isMenuOpen ? "open" : "closed"}`}>
        {/* Menu Header */}
        <div className="side-menu-toggle-container">
          <button className="side-menu-toggle" onClick={toggleMenu}>
            {isMenuOpen ? <CloseMenuBTN /> : <OpenMenuBTN />}
          </button>
          {isMenuOpen && (
            <Logo
              className="side-menu-logo-image"
              style={{
                fill: isDarkMode
                  ? "var(--Changing-Variables-Icons)"
                  : "var(--Changing-Variables-side-menu)",
                transition: "fill 0.3s ease",
              }}
            />
          )}
        </div>

        {/* Profile Section */}
        {(user || admin) && (
          <div className="side-menu-profile-section">
            <img
              src={user?.profile_picture || admin?.profile_picture || defaultpic}
              alt="User Avatar"
              className="side-menu-profile-avatar"
            />
            {isMenuOpen && (
              <div className="side-menu-profile-info">
                <span className="profile-name">
                  {user?.full_name || admin?.full_name || "Admin Name"}
                </span>
                <small className="profile-nickname">
                  {user?.nickname || admin?.nickname || "Admin"}
                </small>
              </div>
            )}
          </div>
        )}

        {/* Dark/Light Mode Toggle */}
        {isMenuOpen ? (
          <div className="colormode side-menu-theme-toggle">
            <div>Dark</div>
            <div className="colorbutton" onClick={toggleTheme}>
              <img
                className="moon-icon"
                src={MoonIcon}
                alt="Moon Icon"
                style={{ opacity: isDarkMode ? 1 : 0 }}
              />
              <img
                className="sun-icon"
                src={SunIcon}
                alt="Sun Icon"
                style={{ opacity: isDarkMode ? 0 : 1 }}
              />
            </div>
            <div>Light</div>
          </div>
        ) : (
          <div className="side-menu-theme-toggle" onClick={toggleTheme}>
            <img
              className="theme-icon"
              src={isDarkMode ? MoonIcon : SunIcon}
              alt="Theme Icon"
            />
          </div>
        )}

        {/* Menu Links */}
        <ul className="side-menu-links">
          {(user || admin) && (
            <>
              {user && (
                <>
                  <li>
                    <Link to="/news">
                      <NewsIcon className="side-menu-icon" />
                      {isMenuOpen && <span>أخبار</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/articles">
                      <ArticlesIcon className="side-menu-icon" />
                      {isMenuOpen && <span>مقالات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/blogs">
                      <BlogsIcon className="side-menu-icon" />
                      {isMenuOpen && <span>مدونات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/reviews">
                      <ReviewsIcon className="side-menu-icon" />
                      {isMenuOpen && <span>تقييمات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/videos">
                      <VideosIcon className="side-menu-icon" />
                      {isMenuOpen && <span>فيديوهات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/characters">
                      <CharactersIcon className="side-menu-icon" />
                      {isMenuOpen && <span>دليل الشخصيات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <FAQIcon className="side-menu-icon" />
                      {isMenuOpen && <span>الأسئلة الأكثر ترددًا</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <AboutIcon className="side-menu-icon" />
                      {isMenuOpen && <span>من نحن</span>}
                    </Link>
                  </li>
                </>
              )}

              {admin && (
                <>
                  <li>
                    <Link to="/admin/dashboard">
                      <VideosIcon className="side-menu-icon" />
                      {isMenuOpen && <span>لوحة الإدارة</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/news">
                      <NewsIcon className="side-menu-icon" />
                      {isMenuOpen && <span>أخبار</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/articles">
                      <ArticlesIcon className="side-menu-icon" />
                      {isMenuOpen && <span>مقالات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/blogs">
                      <BlogsIcon className="side-menu-icon" />
                      {isMenuOpen && <span>مدونات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/reviews">
                      <ReviewsIcon className="side-menu-icon" />
                      {isMenuOpen && <span>تقييمات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/videos">
                      <VideosIcon className="side-menu-icon" />
                      {isMenuOpen && <span>فيديوهات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/characters">
                      <CharactersIcon className="side-menu-icon" />
                      {isMenuOpen && <span>دليل الشخصيات</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/faq">
                      <FAQIcon className="side-menu-icon" />
                      {isMenuOpen && <span>الأسئلة الأكثر ترددًا</span>}
                    </Link>
                  </li>
                  <li>
                    <Link to="/about">
                      <AboutIcon className="side-menu-icon" />
                      {isMenuOpen && <span>من نحن</span>}
                    </Link>
                  </li>
                </>
              )}
            </>
          )}
        </ul>

        {/* Footer Links */}
        <ul className="side-menu-footer">
          <li>
            <Link to="/settings">
              <button className="side-menu-btn">
                <SettingsIcon className="side-menu-icon" />
                {isMenuOpen && <span>إعدادات</span>}
              </button>
            </Link>
          </li>
          <li>
            <button onClick={handleLogout} className="side-menu-btn">
              <LogoutIcon className="side-menu-icon" />
              {isMenuOpen && <span>تسجيل الخروج</span>}
            </button>
          </li>
        </ul>
      </div>

      {/* Settings Modal */}
      <SettingsModal isOpen={isSettingsOpen} closeModal={closeSettings} user={user || admin} />
    </div>
  );
}

export default SideMenu;
