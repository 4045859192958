import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import Swal from "sweetalert2"; // Import SweetAlert2
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as FolderAdd } from "../../../assets/images/info.svg"; // Correct SVG import
import { ReactComponent as TrashIcon } from "../../../assets/images/trash.svg"; // Trash icon for deleting tags
import "./Styles/ArticlesFilters.css";

const ArticlesFilters = () => {
  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState("");
  const [userRole, setUserRole] = useState(null); // Get user role from Dashboard.js (localStorage)

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/tags`);
        setTags(response.data);
      } catch (error) {
        toast.error("❌ خطأ في تحميل الفلاتر");
      }
    };

    // ✅ Fetch user role from localStorage (Dashboard.js saves it)
    const storedAdmin = localStorage.getItem("admin");
    if (storedAdmin) {
      const adminData = JSON.parse(storedAdmin);
      setUserRole(adminData?.role?.toLowerCase()); // Convert role to lowercase for consistency
    } else {
      console.warn("⚠️ No admin role found in localStorage.");
    }

    fetchTags();
  }, []);

  const redirectToLogin = () => {
    const role = localStorage.getItem("role");
    if (role === "admin") {
      window.location.href = "/admin/login"; // Redirect admin to /admin/login
    }
  };

  // ✅ Handle Adding Tags (Only allowed for specific roles)
  const handleAddTag = async () => {
    if (!newTag.trim()) {
      toast.warning("⚠️ أدخل اسم الفلتر أولاً");
      return;
    }

    if (!["admin", "editor", "ceo", "super_admin"].includes(userRole)) {
      toast.error("❌ ليس لديك صلاحية لإضافة الفلاتر.");
      return;
    }

    try {
      const token = localStorage.getItem("accessToken");
      if (!token) {
        console.error("No token found in localStorage.");
        toast.error("❌ لا يوجد رمز مميز. يرجى تسجيل الدخول مرة أخرى.");
        return;
      }

      const response = await axios.post(
        `${process.env.REACT_APP_IMGNBACKEND}/tags`,
        { tag: newTag.trim() },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Use the _id returned by the backend
      setTags([...tags, response.data]);
      setNewTag("");
      toast.success("✅ تمت إضافة الفلتر بنجاح!");
    } catch (error) {
      console.error("Error adding tag:", error.response?.data || error.message);
      toast.error("❌ هذا الفلتر موجود بالفعل أو حدث خطأ");
    }
  };

  // ✅ Handle Deleting Tags (Only allowed for specific roles)
  const handleDeleteTag = async (tagId) => {
    // SweetAlert2 confirmation popup
    const result = await Swal.fire({
      title: "هل أنت متأكد؟",
      text: "هل أنت متأكد أنك تريد حذف هذا الفلتر؟",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "نعم",
      cancelButtonText: "إلغاء",
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
    });
  
    if (!result.isConfirmed) return; // If the user cancels, do nothing
  
    if (!["admin", "editor", "ceo", "super_admin"].includes(userRole)) {
      toast.error("❌ ليس لديك صلاحية لحذف الفلاتر.");
      return;
    }
  
    try {
      const token = localStorage.getItem("accessToken");
      await axios.delete(`${process.env.REACT_APP_IMGNBACKEND}/tags/${tagId}`, {
        headers: { Authorization: `Bearer ${token}` },
      });
  
      setTags(tags.filter((tag) => tag._id !== tagId));
      
  
      // Show success message after deletion
      Swal.fire({
        title: "تم الحذف!",
        text: "تم حذف الفلتر بنجاح",
        icon: "success"
      });
    } catch (error) {
      console.error("Error deleting tag:", error.response?.data || error.message);
      toast.error("❌ فشل في حذف الفلتر.");
    }
  };
  
  return (
    <div className="dashboard-content">
    <div className="articles-filters-container">
      <h2 className="filter-title">فلاتر ثابتة غير قابلة للتغيير</h2>
      <div className="fixed-filters">
        <button className="filter-button">الأكثر مشاهدة</button>
        <button className="filter-button">الأحدث</button>
        <button className="filter-button">الأكثر إعجاباً</button>
        <button className="filter-button">عام</button>
      </div>

      <h2 className="filter-title">فلاتر متغيرة لتفعيل البحث بالعمل الذي ينتمي له الموضوع</h2>
      <div className="dynamic-filters">
        {tags.map((tag) => (
          <div key={tag._id} className="filter-tag-container">
            <span className="filter-tag">{tag.tag}</span>

            {/* Delete Icon - Only for admin/editor/CEO */}
            {["admin", "editor", "ceo", "super_admin"].includes(userRole) && (
              <div className="icon-container" onClick={() => handleDeleteTag(tag._id)}>
                <TrashIcon />
              </div>
            )}
          </div>
        ))}
      </div>

      {/* 🔹 Add New Filter Input (Restricted to Admins, Editors, CEO, Super Admin) */}
      {["admin", "editor", "ceo", "super_admin"].includes(userRole) && (
        <>
          <div className="add-filter-input">
            <input
              type="text"
              placeholder="أدخل اسم الفلتر الجديد..."
              value={newTag}
              onChange={(e) => setNewTag(e.target.value)}
              className="filter-input"
            />
          </div>

          <button className="add-filter-button" onClick={handleAddTag}>
            <FolderAdd className="filter-icon" /> إضافة فلتر جديد
          </button>
        </>
      )}

      <p className="info-text">إضافة فلتر جديد ينعكس بشكل تلقائي في خانة العمل عند النشر</p>

      <ToastContainer position="bottom-right" autoClose={3000} />
    </div></div>
  );
};

export default ArticlesFilters;