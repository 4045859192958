import React, { useState, useEffect } from "react";
import "./Styles/Sidebar.css";

export const sidebarContent = {
  "الرئيسية": [
    "القسم الأول Hero 1 العنوان الرئيسي",
    "القسم الثاني Hero 2 مقالات / مدونات / أخبار",
    "القسم الثالث Hero 3 شخصيات مهمة",
    "القسم الرابع Hero 4 تقييمات",
    "القسم الخامس Hero 5 فيديوهات",
    "القسم السادس Hero 6 من نحن",
    "قنوات السوشيال ميديا",
  ],
  "مقالات": [
    "فلاتر قائمة عرض المقالات",
    "إضافة مقال جديد",
    "تعديل مقال سابق",
    "سجل المقالات السابقة",
    "عرض مقال سابق او طلب نشر",
    "طلبات المراجعة والنشر",
  ],
  "مدونات": [
    "فلاتر قائمة عرض المدونات",
    "إضافة مدونة جديدة",
    "تعديل مدونة سابقة",
    "سجل المدونات السابقة",
    "عرض مدونة سابقة أو طلب نشر",
    "طلبات المراجعة والنشر",
  ],
  "أخبار": [
    "فلاتر قائمة عرض الأخبار",
    "إضافة خبر جديد",
    "تعديل خبر سابق",
    "سجل الأخبار السابقة",
    "عرض خبر سابق أو طلب نشر",
    "طلبات المراجعة والنشر",
  ],
  "فيديوهات": ["إدارة الفيديوهات", "أحدث الفيديوهات"],
  "تقييمات": ["إدارة التقييمات", "أحدث التقييمات"],
  "دليل الشخصيات": ["إدارة الشخصيات", "شخصيات مشهورة"],
  "الأسئلة الأكثر تردداً": ["إدارة الأسئلة", "أكثر الأسئلة شيوعًا"],
  "من نحن": ["إدارة معلومات الصفحة", "تحديث البيانات"],
  "التحليلات والبيانات": ["إحصائيات المستخدمين", "تحليل التفاعل"],
  "الادمنز": ["قائمة الادمنز"],
};

const Sidebar = ({ selectedNav, selectedSidebarItem, setSelectedSidebarItem, isRestrictedRole }) => {
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleItemClick = (item) => {
    setSelectedSidebarItem(item);
    setMobileOpen(false); // Close sidebar on mobile after selection
  };

  // Close sidebar when window resizes to desktop
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) setMobileOpen(false);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <button 
        className="sidebar-toggle" 
        onClick={() => setMobileOpen(!mobileOpen)}
      >
        {mobileOpen ? "إغلاق القائمة" : "القائمة"}
      </button>

      <div className={`dashboard-sidebar ${mobileOpen ? "open" : ""}`}>
        <div className="dashboard-sidebar-links">
          {sidebarContent[selectedNav]?.map((item) => {
            const disabled = isRestrictedRole && 
              (selectedNav === "الرئيسية" || selectedNav === "التحليلات");

            return (
              <div
                key={item}
                className={`dashboard-sidebar-item ${selectedSidebarItem === item ? "active" : ""} ${disabled ? "disabled" : ""}`}
                onClick={() => !disabled && handleItemClick(item)}
              >
                {item}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default Sidebar;