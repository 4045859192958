import React from "react";
import GridCell from "./GridCell";
import "../Styles/GridRow.css";

const GridRow = ({ rowData, rowIndex, layout, setLayout }) => {
  const updateCell = (cellIndex, newContent) => {
    const updatedLayout = [...layout];
    updatedLayout[rowIndex].cells[cellIndex] = newContent;
    setLayout(updatedLayout);
  };

  return (
    <div className={`grid-row ${rowData.mode === "1-col" ? "col-1" : "col-2"}`}>
      {rowData.cells.map((cell, cellIndex) => (
        <GridCell key={cellIndex} cellData={cell} updateCell={(newContent) => updateCell(cellIndex, newContent)} />
      ))}
    </div>
  );
};

export default GridRow;
