import React, { useState, useEffect } from "react";
import axios from "axios";
import { useTheme } from "./ThemeContext";
import "./Footer.css";

function Footer() {
  const { isDarkMode } = useTheme();
  const [socialLinks, setSocialLinks] = useState([]);

  useEffect(() => {
    const fetchSocialData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/social`);
        setSocialLinks(response.data);
      } catch (error) {
        console.error("Error fetching social data:", error);
      }
    };

    fetchSocialData();
  }, []);

  return (
    <footer className="footer">
      <div className="footer-navigation">
        <a href="/articles">مقالات</a>
        <a href="#">مدونات</a>
        <a href="#">أخبار</a>
        <a href="#">فيديوهات</a>
        <a href="#">تقييمات</a>
        <a href="#">دليل الشخصيات</a>
        <a href="#">الأسئلة الأكثر تردداً</a>
        <a href="#">من نحن</a>
      </div>

      <div className="footer-social">
        <h4 className="footer-heading">تابعونا على المنصات الآتية</h4>
        <div className="social-icons">
          {socialLinks.map((platform) => (
            <a
              key={platform._id}
              href={platform.link}
              target="_blank"
              rel="noopener noreferrer"
              className="social-icon"
            >
              <img
                src={platform.icon || "default-icon-url"}
                alt={platform.name}
                className={`social-icon-img ${isDarkMode ? "dark-mode-icon" : "light-mode-icon"}`}
              />
            </a>
          ))}
        </div>
      </div>

      <div className="footer-copyright">
        © IMGN 2022-2025. All Rights Reserved
      </div>
    </footer>
  );
}

export default Footer;