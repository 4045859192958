import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "./pages/Home";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import Settings from "./pages/settings";
import ArticlesPage from "./pages/ArticlesPage";
import ArticleDetail from "./pages/articles/[id]/ArticleDetail";
import AdminSignIn from "./pages/(dashboard)/signin";
import Dashboard from "./pages/(dashboard)/dashboard";
import NotFound from "./pages/NotFound";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.css";

import AuthProvider, { AuthContext } from "./utils/AuthContext";

function App() {
  return (
    <AuthProvider>
      <ToastContainer position="top-center" autoClose={3000} hideProgressBar />
      <AppRoutes />
    </AuthProvider>
  );
}

const AppRoutes = () => {
  const { user, admin, isReady } = useContext(AuthContext);

  useEffect(() => {
    console.log("🔐 AuthContext state:", { user, admin, isReady });
  }, [user, admin, isReady]);

  if (!isReady) {
    return (
      <div className="loading-screen">
        <p>Initializing session...</p>
        {/* You can replace this with a spinner */}
      </div>
    );
  }

  return (
    <Routes>
      <Route path="/" element={<Home />} />
            <Route
            path="/settings"
            element={
              user || admin ? (
                <Settings />
              ) : (
                <Navigate to="/signin" />
              )
            }
      />


      {/* User auth */}
      <Route
        path="/signin"
        element={
          user ? (
            <Navigate to="/" />
          ) : admin ? (
            <Navigate to="/admin/dashboard" />
          ) : (
            <SignIn />
          )
        }
      />
      <Route
        path="/signup"
        element={
          user ? (
            <Navigate to="/" />
          ) : admin ? (
            <Navigate to="/admin/dashboard" />
          ) : (
            <SignUp />
          )
        }
      />

      {/* Articles */}
      <Route path="/articles" element={<ArticlesPage />} />
      <Route path="/articles/:uniqueId" element={<ArticleDetail />} />

      {/* Admin auth */}
      <Route
        path="/admin/login"
        element={
          admin ? (
            <Navigate to="/admin/dashboard" />
          ) : user ? (
            <Navigate to="/" />
          ) : (
            <AdminSignIn />
          )
        }
      />
      <Route
        path="/admin/dashboard/*"
        element={
          admin ? (
            <Dashboard />
          ) : user ? (
            <Navigate to="/" />
          ) : (
            <Navigate to="/admin/login" />
          )
        }
      />

      {/* Fallback */}
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
};


export default App;
