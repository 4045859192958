import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Sidebar, { sidebarContent } from "./Sidebar";
import ContentArea from "./ContentArea";
import Navbar from "./Navbar";
import "./Styles/Dashboard.css";
import api from "../../utils/axiosConfig";
import { initSocket } from "../../utils/socket";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Dashboard = () => {
  const [admin, setAdmin] = useState(null);
  const [selectedNav, setSelectedNav] = useState("مقالات");
  const [selectedSidebarItem, setSelectedSidebarItem] = useState("إدارة المقالات");
  const navigate = useNavigate();
  const location = useLocation();
  // Enhanced notification state
  const [notifications, setNotifications] = useState({
    articles: 0,
    blogs: 0,
    news: 0,
    videos: 0,
    ratings: 0,
    characters: 0,
    faqs: 0,
    reviews: 0
  });
  const [unseenActivities, setUnseenActivities] = useState([]);

  // ✅ Fetch admin from API
  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const res = await api.get("/admin/me");
        setAdmin(res.data);
        localStorage.setItem("admin", JSON.stringify(res.data));
      } catch (err) {
        console.error("Failed to fetch admin profile:", err);
        navigate("/admin/login");
      }
    };

    fetchAdmin();

    const storedNav = localStorage.getItem("selectedNav");
    if (storedNav) setSelectedNav(storedNav);

    const storedSidebarItem = localStorage.getItem("selectedSidebarItem");
    if (storedSidebarItem) {
      setSelectedSidebarItem(storedSidebarItem);
    } else {
      setSelectedSidebarItem(sidebarContent["مقالات"][0]);
    }

    return () => {
      localStorage.removeItem("selectedNav");
      localStorage.removeItem("selectedSidebarItem");
    };
  }, [navigate]);

  // ✅ Handle selectedNav persistence
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) return;
  
    const socket = initSocket(token);
  
    socket.on("connect", () => {
      console.log("✅ Dashboard socket connected:", socket.id);
      // ✅ No need to emit anything — server marks you online
    });
  
    socket.on("admin-profile-update", (updatedAdmin) => {
      setAdmin(updatedAdmin);
      localStorage.setItem("admin", JSON.stringify(updatedAdmin));
    });
  
    const handleContentUpdate = (type, data) => {
      setNotifications((prev) => ({
        ...prev,
        [type]: prev[type] + 1,
      }));
      setUnseenActivities((prev) => [
        ...prev,
        { type, data, timestamp: new Date() },
      ]);
    };
  
    socket.on("article-created", (data) => handleContentUpdate("articles", data));
    socket.on("articlePublished", (data) => {
      handleContentUpdate("articles", data);
      toast.success(`✅ تم نشر مقال جديد: ${data.title}`);
    });
    socket.on("publishRequestReviewed", (data) => {
      if (data.reviewStatus === "approved") {
        handleContentUpdate("reviews", data);
      }
    });
    socket.on("articleSubmitted", (data) => {
      handleContentUpdate("reviews", data);
      toast.info(`📝 مقال جديد يحتاج إلى مراجعة: ${data.title}`);
    });
    socket.on("blog-created", (data) => handleContentUpdate("blogs", data));
    socket.on("blog-approved", (data) => handleContentUpdate("reviews", data));
    socket.on("news-created", (data) => handleContentUpdate("news", data));
    socket.on("news-approved", (data) => handleContentUpdate("reviews", data));
    socket.on("video-uploaded", (data) => handleContentUpdate("videos", data));
    socket.on("rating-added", (data) => handleContentUpdate("ratings", data));
    socket.on("character-added", (data) => handleContentUpdate("characters", data));
    socket.on("faq-added", (data) => handleContentUpdate("faqs", data));
  
    return () => {
      socket.off("connect");
      socket.off("admin-profile-update");
      socket.off("article-created");
      socket.off("articlePublished");
      socket.off("publishRequestReviewed");
      socket.off("articleSubmitted");
      socket.off("blog-created");
      socket.off("blog-approved");
      socket.off("news-created");
      socket.off("news-approved");
      socket.off("video-uploaded");
      socket.off("rating-added");
      socket.off("character-added");
      socket.off("faq-added");
      socket.disconnect();
    };
  }, []);
  
  

  const clearNotifications = (type) => {
    setNotifications(prev => ({
      ...prev,
      [type]: 0
    }));
    // Also clear unseen activities for this type
    setUnseenActivities(prev => 
      prev.filter(activity => activity.type !== type)
    );
  };

  // ✅ Handle edit redirection from other pages
  useEffect(() => {
    if (location.state?.editArticleId) {
      setSelectedSidebarItem("تعديل مقال سابق");
    }
  }, [location.state]);

  const handleLogout = async () => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      localLogout();
      return;
    }

    try {
      await api.post("/admin/logout", {}, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        withCredentials: true,
      });
    } catch (error) {
      console.warn("Logout failed:", error.response?.data || error.message);
    } finally {
      localLogout();
    }
  };

  const localLogout = () => {
    localStorage.removeItem("admin");
    localStorage.removeItem("userRole");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("selectedNav");
    localStorage.removeItem("selectedSidebarItem");

    setTimeout(() => {
      navigate("/admin/login");
      window.location.reload();
    }, 100);
  };

  const isRestrictedRole = admin?.role === "writer" || admin?.role === "editor";

  const navItems = [
    { name: "الادمنز", restricted: false, type: "admins" }, // ✅ New item
    { name: "التحليلات والبيانات", restricted: true, type: "analytics" },
    { name: "من نحن", restricted: false, type: "about" },
    { name: "الأسئلة الأكثر تردداً", restricted: false, type: "faqs" },
    { name: "دليل الشخصيات", restricted: false, type: "characters" },
    { name: "تقييمات", restricted: false, type: "ratings" },
    { name: "فيديوهات", restricted: false, type: "videos" },
    { name: "أخبار", restricted: false, type: "news" },
    { name: "مدونات", restricted: false, type: "blogs" },
    { name: "مقالات", restricted: false, type: "articles" },
    { name: "الرئيسية", restricted: true, type: "home" },
  ];

  return (
    <div className="dashboard-container">
      <Navbar
        admin={admin}
        selectedNav={selectedNav}
        setSelectedNav={(newNav) => {
          if (!isRestrictedRole || (newNav !== "الرئيسية" && newNav !== "التحليلات")) {
            setSelectedNav(newNav);
            localStorage.setItem("selectedNav", newNav);

            const defaultItem = sidebarContent[newNav]?.[0];
            if (defaultItem) {
              setSelectedSidebarItem(defaultItem);
              localStorage.setItem("selectedSidebarItem", defaultItem);
            }

          }
        }}
        navItems={navItems}
        setSelectedSidebarItem={(item) => {
          setSelectedSidebarItem(item);
          localStorage.setItem("selectedSidebarItem", item);
        }}
        notifications={notifications}
        clearNotifications={clearNotifications}
        unseenActivities={unseenActivities}
      />
      <Sidebar
        selectedNav={selectedNav}
        selectedSidebarItem={selectedSidebarItem}
        setSelectedSidebarItem={(item) => {
          setSelectedSidebarItem(item);
          localStorage.setItem("selectedSidebarItem", item);
        }}
        isRestrictedRole={isRestrictedRole}
        notifications={notifications}
      />
      <ContentArea
        selectedNav={selectedNav}
        selectedSidebarItem={selectedSidebarItem}
      />
    </div>
  );
};

export default Dashboard;