import React from "react";
import GridRow from "./GridRow";
import "../Styles/GridEditor.css";

const GridEditor = ({ layout, setLayout }) => {
  const addRow = (mode = "2-col") => {
    if (mode === "1-col") {
      setLayout([...layout, { mode, cells: [{ type: "text", content: "" }] }]);
    } else {
      setLayout([...layout, { mode, cells: [{ type: "text", content: "" }, { type: "text", content: "" }] }]);
    }
  };

  const deleteRow = (index) => {
    const newLayout = layout.filter((_, i) => i !== index);
    setLayout(newLayout);
  };

  return (
    <div className="grid-editor-container">
      <h2> Grid UI (الابعاد والمقاسات تختلف عن الابعاد الحقيقيه)</h2>
      {layout.map((row, index) => (
        <div key={index} className="grid-row-wrapper">
          <button className="delete-row-button" onClick={() => deleteRow(index)}>🗑️ حذف الصف</button>
          <div className="grid-row-container">
            <GridRow rowData={row} rowIndex={index} layout={layout} setLayout={setLayout} />
          </div>
        </div>
      ))}
      <div className="add-row-buttons">
        <button onClick={() => addRow("1-col")}>➕ إضافة صف بعرض كامل</button>
        <button onClick={() => addRow("2-col")}>➕ إضافة صف بعمودين</button>
      </div>
    </div>
  );
};

export default GridEditor;
