import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import lightlogo from "../assets/images/lightlogo.svg";
import harry from "../assets/images/HarryPotter.png";
import "react-toastify/dist/ReactToastify.css";
import "./SignUp.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function SignUp() {
  const [full_name, setFullName] = useState("");
  const [nickname, setNickname] = useState("");
  const [birthday, setBirthday] = useState(null);
  const [phone_number, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [terms, setTerms] = useState(false);
  const navigate = useNavigate();

  // Redirect to home if user is already logged in
  useEffect(() => {
    if (localStorage.getItem("user")) {
      navigate("/"); // Redirect to home if already logged in
    }
  }, [navigate]);

  // Calculate min/max birthdate
  const today = new Date();
  const maxDate = new Date(today.getFullYear() - 13, today.getMonth(), today.getDate()); // Minimum age: 13
  const minDate = new Date(today.getFullYear() - 100, today.getMonth(), today.getDate()); // Optional: Max age 100

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!full_name || !nickname || !email || !birthday) {
      toast.error("All fields are required.");
      return;
    }
  
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      toast.error("Invalid email format.");
      return;
    }
  
    if (password !== confirmPassword) {
      toast.error("Passwords do not match.");
      return;
    }
  
    if (!terms) {
      toast.error("You must agree to the terms and conditions.");
      return;
    }
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_IMGNBACKEND}/users/register`, {
        full_name,
        nickname,
        phone_number: phone_number || undefined,
        email,
        password,
        birthday: new Date(birthday).toISOString(),
      });
  
      if (response.status === 201) {
        toast.success("Account created successfully! Redirecting to login...");
  
        // Redirect to the login page after successful registration
        setTimeout(() => navigate("/signin"), 2000); // Redirect to login page after 2 seconds
      }
    } catch (error) {
      toast.error(error.response?.data?.message || "Something went wrong");
    }
  };
  

  return (
    <div className="sign-up-wrapper">
      <div className="sign-up-left">
        <img src={harry} alt="Character" className="sign-up-character-img" />
      </div>
      <div className="sign-up-container">
        <div className="sign-up-right">
          <h4>Sign Up Now</h4>
          <p>Start your Journey Right Now!</p>

          <form onSubmit={handleSubmit}>
            <input
              type="text"
              className="input-field"
              placeholder="Your Full Name"
              value={full_name}
              onChange={(e) => setFullName(e.target.value)}
            />
            <input
              type="text"
              className="input-field"
              placeholder="Your Imaginary Nickname"
              value={nickname}
              onChange={(e) => setNickname(e.target.value)}
            />

            {/* DatePicker with Min Age 13 Restriction */}
            <div>
              <DatePicker
                selected={birthday}
                onChange={(date) => setBirthday(date)}
                dateFormat="dd/MM/yyyy"
                className="input-field_date"
                placeholderText="Select your birthday"
                maxDate={maxDate} // Minimum age: 13
                minDate={minDate} // Optional: Max age 100
                showYearDropdown
                scrollableYearDropdown
                yearDropdownItemNumber={100} // Show 100 years in dropdown
              />
            </div>

            <input
              type="text"
              className="input-field"
              placeholder="Phone Number (e.g., +201234567890)"
              value={phone_number}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <input
              type="email"
              className="input-field"
              placeholder="Your Email Address"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <input
              type="password"
              className="input-field"
              placeholder="Password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
            <input
              type="password"
              className="input-field"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />

            <div className="terms">
              <input
                type="checkbox"
                id="terms"
                checked={terms}
                onChange={() => setTerms(!terms)}
              />
              <label htmlFor="terms">I agree to the terms and conditions</label>
            </div>
            <button type="submit" className="primary-button-signup" disabled={!terms}>
              Sign Up
            </button>
          </form>
          <p className="login-link">
            Already have an account?{" "}
            <Link to="/signin" className="login-now">
              Login Now
            </Link>
          </p>
        </div>
      </div>
      <img src={lightlogo} alt="IMGN Logo" className="imgn-logo" />
    </div>
  );
}

export default SignUp;
