// src/components/HeroSection1.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import { ReactComponent as FolderAdd } from "../../assets/images/folder-add.svg";
import { ReactComponent as ArchiveTick } from "../../assets/images/archivetick.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getSocket } from "../../utils/socket";
const MAX_CHARACTERS = 10;

const HeroSection1 = () => {
  const [heroData, setHeroData] = useState({
    title: "",
    showCarousel: true,
    sections: [],
    carouselCharacters: [],
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchHeroData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero1`);
        setHeroData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("❌ Error fetching Hero Section 1:", error);
        setLoading(false);
        toast.error("❌ Failed to fetch Hero Section 1 data");
      }
    };
  
    fetchHeroData();
  }, []);
  

  const handleTextChange = (index, value) => {
    const updatedSections = [...heroData.sections];
    updatedSections[index].label = value;
    setHeroData({ ...heroData, sections: updatedSections });
  };
  const handleImageUpload = async (event) => {
    const files = event.target.files;
    if (!files.length) return;
  
    const uploadedImages = [];
    const base64Images = [];
  
    try {
      const uploadPromises = Array.from(files).map((file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = async () => {
            try {
              const base64Image = reader.result.split(",")[1];
              base64Images.push(base64Image);
              if (base64Images.length === files.length) {
                try {
                  const response = await axios.post(
                    `${process.env.REACT_APP_IMGNBACKEND}/hero-section/upload-base64/hero1`, // Add the sectionId here
                    { images: base64Images },
                    { headers: { "Content-Type": "application/json" } }
                  );
                  if (response.data.success) {
                    response.data.newCharacters.forEach((newCharacter) => {
                      uploadedImages.push(newCharacter);
                      toast.success(`${file.name} uploaded successfully!`);
                    });
                    setHeroData((prevData) => ({
                      ...prevData,
                      carouselCharacters: [
                        ...prevData.carouselCharacters,
                        ...uploadedImages,
                      ],
                    }));
                  }
                } catch (uploadError) {
                  toast.error("❌ Image upload failed!");
                  reject(uploadError);
                }
              }
            } catch (error) {
              console.error("Upload Error:", error);
              reject(error);
            }
          };
        });
      });
  
      await Promise.all(uploadPromises);
    } catch (error) {
      console.error("❌ Unexpected Upload Error:", error);
      toast.error("❌ Unexpected error occurred while uploading!");
    }
  };
  

  const handleDeleteImage = async (public_id) => {
    try {
      const cloudinaryPublicId = public_id.replace('HeroSection/', ''); // Remove the 'HeroSection/' prefix
  
      // Make sure to pass the correct section ID (e.g., "hero1", "hero3") when deleting
      const sectionId = "hero1"; // Update this to dynamically handle each section
  
      const response = await axios.delete(
        `${process.env.REACT_APP_IMGNBACKEND}/hero-section/delete/${cloudinaryPublicId}`,
        {
          data: { sectionId: sectionId }  // Send the section ID along with the delete request
        }
      );
  
      if (response.data.success) {
        setHeroData((prevData) => ({
          ...prevData,
          carouselCharacters: response.data.data.carouselCharacters,
        }));
        toast.success("✅ Image deleted successfully!");
      } else {
        toast.error("❌ Error deleting image!");
      }
    } catch (error) {
      console.error("❌ Error deleting image:", error);
      toast.error("❌ Failed to delete image");
    }
  };
  

  const handleSave = async () => {
    try {
      await axios.put(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero1`, heroData);
  
      // Emit socket event from frontend (optional, if backend doesn't handle it)
      const socket = getSocket();
      if (socket && socket.connected) {
        socket.emit("heroSectionUpdated", heroData); // optional: can just send true
      }
  
      toast.success("✅ Changes saved successfully!");
    } catch (error) {
      console.error("❌ Save error:", error);
      toast.error("❌ Failed to save changes!");
    }
  };

  if (loading) return <p>⏳ جاري التحميل...</p>;
  if (!heroData) return <p>❌ لا يوجد بيانات لهذا القسم.</p>;

  return (
    <div className="dashboard-content">
      <h2 className="dashboard-content-title">{heroData.title}</h2>

      {heroData.sections.map((section, index) => (
        <div key={index}>
          {section.type === "subtitle" ? (
            <h3 className="dashboard-content-subtitle">{section.label}</h3>
          ) : (
            <input
              className="dashboard-content-input"
              value={section.label}
              onChange={(e) => handleTextChange(index, e.target.value)}
            />
          )}
        </div>
      ))}

      {/* Carousel for Hero Section */}
      
        <>
          <h3 className="dashboard-content-subtitle">كاروسيل الشخصيات</h3>
          <div className="dashboard-character-carousel">
            {heroData.carouselCharacters.map((char, index) => (
              <div key={index} className="dashboard-character-item">
                <img src={char.url} alt={`شخصية ${index + 1}`} className="dashboard-character-image" />
                <button
                  className="dashboard-delete-button"
                  onClick={() => handleDeleteImage(char.public_id)}
                >
                  ✖
                </button>
              </div>
            ))}
            {/* Show placeholder if the number of characters is less than MAX_CHARACTERS */}
            {Array.from({ length: MAX_CHARACTERS - heroData.carouselCharacters.length }).map((_, index) => (
              <div key={index} className="dashboard-character-item">
                شخصية {heroData.carouselCharacters.length + index + 1}
                <FolderAdd className="dashboard-icon" />
              </div>
            ))}
            {heroData.carouselCharacters.length < MAX_CHARACTERS && (
              <label className="dashboard-character-item upload">
                <FolderAdd className="dashboard-icon" />
                <input type="file" multiple onChange={handleImageUpload} style={{ display: "none" }} />
              </label>
            )}
          </div>
          <p className="dashboard-character-info">
            صور الشخصيات تكون بصيغة PNG بمقاسات 464*232 بيكسل
          </p>
        </>
     

      <button className="dashboard-save-button" onClick={handleSave}>
        <ArchiveTick className="dashboard-icon save-icon" />
        حفظ التغييرات والنشر
      </button>

      <ToastContainer />
    </div>
  );
};

export default HeroSection1;
