import React from "react";
import TinyMCEEditor from "../../../components/TinyMCEEditor";
import "../Styles/GridCell.css";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast, Bounce } from "react-toastify";

const GridCell = ({ cellData, updateCell }) => {
  const handleImageUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const formData = new FormData();
    formData.append("file", file);

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      toast.error("❌ فشل تحميل الصورة: لم يتم تسجيل الدخول.");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IMGNBACKEND}/articles/upload-image`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${accessToken}`,
          },
        }
      );

      if (response.data.imageUrl) {
        updateCell({ type: "image", content: response.data.imageUrl });
        toast.success("✅ تم تحميل الصورة بنجاح!", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          rtl: true, // Right-to-left for Arabic
        });
      }
    } catch (error) {
      console.error("🚨 Image Upload Failed:", error.response?.data || error);
      if (error.response?.status === 401) {
        toast.error("❌ فشل تحميل الصورة: يرجى تسجيل الدخول أولاً.");
      } else if (error.response?.status === 413) {
        toast.error("❌ فشل تحميل الصورة: حجم الملف كبير جداً.");
      } else {
        toast.error("❌ فشل تحميل الصورة.");
      }
    }
  };

  const handleDeleteImage = () => {
    updateCell({ type: "image", content: "" });
    toast.info("🗑️ تم حذف الصورة.", {
      position: "top-right",
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      rtl: true, // Right-to-left for Arabic
    });
  };

  return (
    <div className="grid-cell">
      <div className="cell-options">
        <button onClick={() => updateCell({ type: "text", content: "" })}>📝 نص</button>
        <button onClick={() => updateCell({ type: "image", content: "" })}>🖼️ صورة</button>
      </div>
      {cellData.type === "text" ? (
        <TinyMCEEditor value={cellData.content} onChange={(newContent) => updateCell({ type: "text", content: newContent })} />
      ) : (
        <div className="image-upload">
          {!cellData.content ? (
            <>
              <input type="file" id="file-upload" accept="image/*" onChange={handleImageUpload} />
              <label htmlFor="file-upload" className="upload-button">📷 رفع صورة</label>
            </>
          ) : (
            <div className="image-preview">
              <img src={cellData.content} alt="معاينة التحميل" className="uploaded-image" />
              <button className="delete-image-button" onClick={handleDeleteImage}>
                ✖
              </button>
            </div>
          )}
        </div>

      )}
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={true} // Enable right-to-left for Arabic
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
        transition={Bounce}
      />
    </div>
  );
};

export default GridCell;
