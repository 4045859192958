import React, { useState, useEffect } from "react";
import axios from "axios";
import "./CharacterSection.css";

// Dynamic data fetching for character description
function CharacterSection() {
  const [characterData, setCharacterData] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch character data from backend (similar to HeroSection3)
  useEffect(() => {
    const fetchCharacterData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/hero-section/hero3`);
        setCharacterData(response.data);  // Assuming data contains character details
        setLoading(false);
      } catch (error) {
        console.error("❌ Error fetching character data:", error);
        setLoading(false);
      }
    };

    fetchCharacterData();
  }, []);

  // Dynamically format description with line breaks
  const formattedDescription = characterData?.sections[8]?.label?.split("\n").map((line, index) => (
    <span key={index} className="line-break">
      {line}
    </span>
  ));

  if (loading) return <p>⏳ Loading character data...</p>;
  if (!characterData) return <p>❌ No character data found.</p>;

  return (
    <section className="character-section">
      <h3 className="character-section-title">شخصيات مهمة</h3>
      <p className="character-section-subtitle">
        تعرف على شخصية تخيل المهمة المختارة لكم وبعض المعلومات الجديدة عنها
      </p>

      <div className="character-container">
        <div className="character-card">
          <img
            src={characterData.carouselCharacters[0]?.url || "/CharacterCard.png"}  // Dynamic image URL
            alt="Character Image"
            className="character-card-image"
          />
        </div>

        <div className="character-details">
          <h3 className="character-name">{characterData.sections[4]?.label || "Character Name"}</h3> {/* Character Name */}
          
          {/* World Title */}
          <h4 className="character-world-title">
            ينتمي إلى عالم : {characterData.sections[6]?.label || "Unknown World"}
          </h4>

          {/* Render dynamic description */}
          <div className="character-world-description">
            {formattedDescription}
          </div>

          {/* Actor's Name */}
          <h4 className="character-actor-title">
            أسم مؤدي الدور : {characterData.sections[10]?.label || "Actor Name"}
          </h4>

          <div className="actor-section">
            <button className="character-learn-more">أعرف أكثر عن الشخصية</button>
          </div>
        </div>
      </div>

      <div className="character-actions">
        <button className="character-guide">إنتقل إلى دليل الشخصيات</button>
      </div>
    </section>
  );
}

export default CharacterSection;
