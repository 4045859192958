import React, { useState } from "react";
import "./Styles/Navbar.css";

const Navbar = ({
  selectedNav,
  setSelectedNav,
  admin,
  navItems,
  notifications,
  clearNotifications,
  unseenActivities,
}) => {
  const [showNotificationCenter, setShowNotificationCenter] = useState(false);
  const [mobileOpen, setMobileOpen] = useState(false);
  const isRestrictedRole = admin?.role === "writer" || admin?.role === "editor";

  const handleNavClick = (name, type) => {
    setSelectedNav(name);
    localStorage.setItem("selectedNav", name);
    setMobileOpen(false); // Close on mobile
    if (type && notifications[type] > 0) {
      clearNotifications(type);
    }
  };

  const getNotificationCount = (type) => notifications[type] || 0;

  const notificationTypes = {
    articles: "مقالات جديدة",
    blogs: "مدونات جديدة",
    news: "أخبار جديدة",
    videos: "فيديوهات جديدة",
    ratings: "تقييمات جديدة",
    characters: "شخصيات جديدة",
    faqs: "أسئلة جديدة",
    reviews: "طلبات مراجعة",
  };

  return (
    <nav className="dashboard-navbar">
      {/* ☰ Toggle for mobile */}
      <button
        className="navbar-toggle"
        onClick={() => setMobileOpen((prev) => !prev)}
      >
        ☰
      </button>

      {/* 👤 Admin Profile + 🔔 Bell */}
      <div className="dashboard-profile-container">
        <img
          src={admin?.profile_picture || "/assets/default-avatar.png"}
          alt="Profile"
          className="dashboard-profile-avatar"
        />
        <div className="dashboard-profile-info">
          <span className="dashboard-profile-name">{admin?.full_name || "Admin"}</span>
          <span className="dashboard-profile-role">{admin?.role || "Owner"}</span>
        </div>

        {/* 🔔 Notification Bell */}
        <div
          className="notification-bell"
          onClick={() => setShowNotificationCenter(!showNotificationCenter)}
        >
          🔔
          {Object.values(notifications).some((count) => count > 0) && (
            <span className="notification-bell-badge"></span>
          )}
        </div>
      </div>

      {/* 📬 Notification Dropdown */}
      {showNotificationCenter && (
        <div className="notification-center">
          <div className="notification-center-header">
            <h3>الإشعارات الحديثة</h3>
            <button onClick={() => setShowNotificationCenter(false)}>✖</button>
          </div>
          <div className="notification-list">
            {unseenActivities.length > 0 ? (
              unseenActivities.map((activity, index) => (
                <div key={index} className="notification-item">
                  <span className="notification-type">
                    {notificationTypes[activity.type] || activity.type}
                  </span>
                  <span className="notification-message">
                    {activity.data.title || "محتوى جديد"}
                  </span>
                  <span className="notification-time">
                    {new Date(activity.timestamp).toLocaleTimeString()}
                  </span>
                </div>
              ))
            ) : (
              <div className="no-notifications">لا توجد إشعارات جديدة</div>
            )}
          </div>
        </div>
      )}

      {/* 🧭 Navigation */}
      <div className={`dashboard-nav-links ${mobileOpen ? "open" : ""}`}>
        {navItems.map((item) => {
          if (isRestrictedRole && item.restricted) {
            return (
              <span
                key={item.name}
                className="dashboard-nav-item disabled"
                style={{ color: "#ccc", cursor: "not-allowed" }}
              >
                {item.name}
              </span>
            );
          }

          const notificationCount = getNotificationCount(item.type);
          const hasNotifications = notificationCount > 0;

          return (
            <div key={item.name} className="nav-item-container">
              <span
                className={`dashboard-nav-item ${selectedNav === item.name ? "active" : ""}`}
                onClick={() => handleNavClick(item.name, item.type)}
              >
                {item.name}
                {hasNotifications && (
                  <span className="notification-badge">{notificationCount}</span>
                )}
              </span>
            </div>
          );
        })}
      </div>
    </nav>
  );
};

export default Navbar;
