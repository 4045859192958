import { io } from "socket.io-client";
import { toast } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";

let socket;
let isManualDisconnect = false;

export const getSocket = () => socket;

export const initSocket = (token = null, clearAuth = () => {}) => {
  if (!token) {
    console.warn("⛔ No token found, skipping socket connection.");
    return null;
  }

  // Disconnect if already connected to ensure clean session
  if (socket) {
    if (socket.connected) {
      console.warn("⚠️ Socket already connected:", socket.id);
      return socket;
    }
    socket.removeAllListeners(); // remove old listeners before reconnect
  }

  socket = io(process.env.REACT_APP_SOCKETBACKEND, {
    withCredentials: true,
    autoConnect: false,
    auth: { token },
  });

  // ✅ Successful connection
  socket.on("connect", () => {
    console.log("🟢 Socket connected:", socket.id);

    // Send re-auth only for backend logging or room joining
    socket.emit("re-auth", token);

    isManualDisconnect = false;
  });

  // ✅ Force logout from backend (e.g., deactivation)
  socket.on("force-logout", (reason) => {
    console.warn("🚨 Force logout triggered:", reason);

    toast.error(reason || "تم تسجيل خروجك من قبل المدير.");

    localStorage.removeItem("admin");
    localStorage.removeItem("accessToken");
    localStorage.removeItem("refreshToken");
    localStorage.removeItem("role");

    isManualDisconnect = true;
    socket.disconnect();

    window.location.href = "/admin/login";
  });
  socket.on("notification", (payload) => {
  toast.info(payload.message || "📢 لديك إشعار جديد");
});

  // ✅ Handle disconnect (automatic)
  socket.on("disconnect", (reason) => {
    console.warn("🔴 Socket disconnected:", reason);

    if (isManualDisconnect) return;

    // Try to reconnect up to 3 times
    let retryAttempts = 0;
    const retryInterval = setInterval(() => {
      if (socket.connected) {
        console.log("🔄 Reconnected before logout.");
        clearInterval(retryInterval);
      } else if (++retryAttempts >= 3) {
        console.warn("🛑 Logout after failed reconnect attempts.");
        clearInterval(retryInterval);
        clearAuth();
      }
    }, 1500);
  });

  // ✅ Error handler
  socket.on("connect_error", (err) => {
    console.error("❌ Socket error:", err.message);
  });

  socket.connect();
  return socket;
};

export const disconnectSocket = () => {
  if (socket && socket.connected) {
    isManualDisconnect = true;
    socket.disconnect();
  }
};
