import React, { useState, useEffect } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ReactComponent as FolderAdd } from "../../assets/images/folder-add.svg"; // For image upload
import checkmarkIcon from "../../assets/images/checkmark.png"; // Check mark icon
import { ReactComponent as ArchiveTick } from "../../assets/images/archivetick.svg"; // Save button icon

const SocialSection = () => {
  const [socialData, setSocialData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [newPlatformName, setNewPlatformName] = useState("");
  const [newPlatformUrl, setNewPlatformUrl] = useState("");
  const [newPlatformIcon, setNewPlatformIcon] = useState(""); // For storing icon URL
  const [newPlatformVisibility, setNewPlatformVisibility] = useState(false); // For visibility toggle

  useEffect(() => {
    const fetchSocialData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/social`);
        setSocialData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("❌ Error fetching social data:", error);
        setLoading(false);
        toast.error("❌ Failed to fetch social data");
      }
    };

    fetchSocialData();
  }, []);

  // Handle visibility toggle of a platform
// Handle visibility toggle of a platform
const handleVisibilityToggle = async (id, currentVisibility) => {
  try {
    const updatedVisibility = !currentVisibility;  // Toggle visibility
    const response = await axios.put(`${process.env.REACT_APP_IMGNBACKEND}/social/update-visibility/${id}`, {
      visible: updatedVisibility,
    });

    if (response.data) {
      setSocialData((prevData) =>
        prevData.map((social) =>
          social._id === id ? { ...social, visible: updatedVisibility } : social
        )
      );
      toast.success("✅ Visibility updated successfully!");
    }
  } catch (error) {
    console.error("Error updating visibility:", error);
    toast.error("❌ Failed to update visibility");
  }
};


  // Add new platform to the database
  const handleAddPlatform = async () => {
    try {
      const newPlatform = {
        name: newPlatformName,
        link: newPlatformUrl,
        icon: newPlatformIcon || "default-icon-url", // Use uploaded icon or default if not uploaded
        visible: newPlatformVisibility,
      };
  
      const response = await axios.post(`${process.env.REACT_APP_IMGNBACKEND}/social/add`, newPlatform);
      setSocialData([...socialData, response.data]); // Update the state with the new platform data
      toast.success("✅ Platform added successfully!");
  
      // Reset form fields after adding the platform
      setNewPlatformName("");
      setNewPlatformUrl("");
      setNewPlatformIcon(""); // Clear the uploaded icon
      setNewPlatformVisibility(false); // Reset visibility toggle
    } catch (error) {
      console.error("Error adding platform:", error);
      toast.error("❌ Failed to add platform");
    }
  };
  

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) {
      toast.error("❌ No file selected!");
      return;
    }
  
    const formData = new FormData();
    formData.append("icon", file);
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_IMGNBACKEND}/social/upload-social-icon`,
        formData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      );
  
      // Log the response to check its structure
      console.log("Upload response:", response);
  
      if (response.status === 200 && response.data.url) {
        setNewPlatformIcon(response.data.url);
        console.log("Uploaded icon URL:", response.data.url);
        toast.success("✅ Icon uploaded successfully!");
      } else {
        toast.error("❌ Failed to upload icon. No URL received.");
      }
    } catch (error) {
      console.error("❌ Error uploading social icon:", error);
      toast.error("❌ Failed to upload social icon");
    }
  };

  const handleDeletePlatform = async (id) => {
    try {
      const response = await axios.delete(`${process.env.REACT_APP_IMGNBACKEND}/social/delete/${id}`);
      setSocialData(socialData.filter(platform => platform._id !== id)); // Remove deleted platform from state
      toast.success("✅ Platform deleted successfully!");
    } catch (error) {
      console.error("❌ Error deleting platform:", error);
      toast.error("❌ Failed to delete platform");
    }
  };
  

  // Handle deleting the uploaded image
  const handleDeleteImage = () => {
    setNewPlatformIcon(""); // Clear the uploaded icon
    toast.success("✅ Icon deleted successfully!");
  };

  if (loading) return <p>⏳ جاري التحميل...</p>;
  if (!socialData) return <p>❌ لا يوجد بيانات لهذه المنصات.</p>;

  return (
    <div className="dashboard-content">
      <h2 className="dashboard-content-title">قنوات السوشيال ميديا</h2>
  
      {/* Display Social Platforms */}
      {socialData.map((platform) => (
  <div key={platform._id} className="dashboard-social-platform">
    {/* Platform Header with Delete on the Left, Icon and Name on the Right */}
    <div className="dashboard-social-header">
      {/* Delete Button on the Far Left */}
      <button
            onClick={() => handleDeletePlatform(platform._id)} // Delete platform by ID
            className="dashboard-delete-btn"
          >
            حذف
      </button>

      {/* Icon and Name on the Far Right */}
      <div className="dashboard-social-right">
      <h3 className="dashboard-social-name">{platform.name}</h3>
        <img
          src={platform.icon || "default-icon-url"} // Use the icon URL from the backend
          alt={platform.name}
          className="dashboard-social-icon"
        />
        
      </div>
    </div>

    {/* Platform Visibility Toggle (Below Icon and Name, Above URL) */}
    <div className="check-mark-parent">
      <div
        className="check-mark-option"
        onClick={() => handleVisibilityToggle(platform._id, platform.visible)} // Pass the current visibility state
      >
        <div className={`check-mark-icon ${platform.visible ? "checked" : ""}`}>
          {platform.visible && <img src={checkmarkIcon} alt="Checkmark" />}
        </div>
        <span className="div">إظهار المنصة</span>
      </div>

      <div
        className="check-mark-option"
        onClick={() => handleVisibilityToggle(platform._id, platform.visible)} // Pass the current visibility state
      >
        <div className={`check-mark-icon ${!platform.visible ? "checked" : ""}`}>
          {!platform.visible && <img src={checkmarkIcon} alt="Checkmark" />}
        </div>
        <span className="div">إخفاء المنصة</span>
      </div>
    </div>

    {/* Platform Link */}
    <input
      type="url"
      value={platform.link}
      className="dashboard-content-input"
      readOnly
    />
  </div>
))}




  
      {/* Add New Platform Form */}
      <div className="dashboard-social-platform-form">
        <h3 className="dashboard-b">إضافة منصة جديدة</h3>
  
        {/* Platform Name */}
        <input
          type="text"
          placeholder="إسم المنصة"
          value={newPlatformName}
          onChange={(e) => setNewPlatformName(e.target.value)}
          className="dashboard-content-input"
        />
  
        {/* Platform Icon */}
        <div className="dashboard-character-carousel">
          {newPlatformIcon && (
            <div className="dashboard-character-item">
              <img
                src={newPlatformIcon}
                alt="Platform Icon"
                className="dashboard-character-image"
              />
              <button
                className="dashboard-delete-button"
                onClick={() => handleDeleteImage()} // Delete function for the uploaded image
              >
                ✖
              </button>
            </div>
          )}
  
          {/* File upload button */}
          {!newPlatformIcon && (
            <label className="dashboard-character-item upload">
              <FolderAdd className="dashboard-icon" />
              <input
                type="file"
                onChange={handleFileUpload}
                style={{ display: "none" }} // Hide the default file input
              />
            </label>
          )}
        </div>
  
        {/* Platform URL */}
        <input
          type="url"
          placeholder="رابط المنصة"
          value={newPlatformUrl}
          onChange={(e) => setNewPlatformUrl(e.target.value)}
          className="dashboard-content-input"
        />
  

  
        {/* Save New Platform */}
        <button onClick={handleAddPlatform} className="dashboard-save-button">
          إضافة منصة جديدة
        </button>
      </div>
  
      {/* Save Changes Button */}
      <button className="dashboard-save-button">
        <ArchiveTick className="dashboard-icon save-icon" />
        حفظ التغييرات والنشر
      </button>
  
      <ToastContainer />
    </div>
  );
  
};

export default SocialSection;
