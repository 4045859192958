// src/pages/Home.js
import React from 'react';
import Header from '../components/Header';
import HeroSection from '../components/HeroSection';
import Articles from '../components/Articles';
import CharacterSection from '../components/CharacterSection';
import RatingSection from '../components/RatingSection';
import AboutUsSection from '../components/AboutUsSection';
import Footer from '../components/Footer';
import './Home.css';
import SideMenu from '../components/SideMenu';

import testImage from '../assets/images/test.png';



function Home() {
  return (
    <div>
      
      <Header />
      <HeroSection />
      <Articles/>
      <CharacterSection />
      <RatingSection/>
      <AboutUsSection/>
      <Footer/>
      <SideMenu />
      
     
    </div>
  );
}

export default Home;
