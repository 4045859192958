import React from 'react';
import ReactDOM from 'react-dom/client'; // Use react-dom/client for React 18+
import App from './App'; // Import the App component
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from './components/ThemeContext'; // Import the ThemeProvider

const root = ReactDOM.createRoot(document.getElementById('root')); // Create a root
// root.render(
  
//   <React.StrictMode>
//     <ThemeProvider> {/* Wrap ThemeProvider around the App */}
//       <BrowserRouter>
//         <App />
//       </BrowserRouter>
//     </ThemeProvider>
//   </React.StrictMode>
// );
root.render(
  <ThemeProvider>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </ThemeProvider>
);