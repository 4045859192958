import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTheme } from "./ThemeContext"; // Import ThemeContext hook
import "./Header.css";

function Header() {
  const { isDarkMode, toggleTheme } = useTheme(); // Use ThemeContext
  const [user, setUser] = useState(null);
  const [admin, setAdmin] = useState(null);

  // Only fetch data from localStorage once, don't repeatedly trigger state updates
  useEffect(() => {
    const storedUser = localStorage.getItem("user");
    const storedAdmin = localStorage.getItem("admin");

    // Set state only if it's not already set
    if (!user && storedUser) {
      setUser(JSON.parse(storedUser));
    }

    if (!admin && storedAdmin) {
      setAdmin(JSON.parse(storedAdmin));
    }
  }, []); // Empty dependency array ensures this only runs once

  return (
    <header>
      <div className="logo">
        <img id="logo" src={isDarkMode ? "/dark-logo.svg" : "/light-logo.svg"} alt="IMGN Logo" />
      </div>
      <div className="search-bar">
        <img src="/Vector.svg" alt="Search Icon" />
        Search about your imagination
      </div>
      <div className="colormode">
        <div>Dark</div>
        <div className="colorbutton" onClick={toggleTheme}>
          <img className="moon-icon" src="/moon-icon.svg" alt="Moon Icon" />
          <img className="sun-icon" src="/sun-icon.png" alt="Sun Icon" />
        </div>
        <div>Light</div>
      </div>
      <nav>
        <ul>
          {/* Hide sign-in button if user or admin is logged in */}
          {!user && !admin && (
            <li>
              <Link to="/signin" className="signin-button">
                كن متخيلًا الآن
              </Link>
            </li>
          )}
        </ul>
      </nav>
    </header>
  );
}

export default Header;
