import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import Header from "../components/PageHeader";
import Footer from "../components/Footer";
import test1 from "../assets/images/like.svg";
import test2 from "../assets/images/comment.svg";
import axios from "axios";
import "./ArticlesPage.css";
import { getSocket } from "../utils/socket";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ArticlesPage = ({ heroData }) => {
  const [articles, setArticles] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("الجميع");
  const [selectedTag, setSelectedTag] = useState("");

  const selectedCategoryRef = useRef(selectedCategory);
  const selectedTagRef = useRef(selectedTag);

  useEffect(() => {
    selectedCategoryRef.current = selectedCategory;
  }, [selectedCategory]);

  useEffect(() => {
    selectedTagRef.current = selectedTag;
  }, [selectedTag]);

  // Initial fetch
  useEffect(() => {
    const fetchArticlesAndTags = async () => {
      try {
        const articlesResponse = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/articles?reviewStatus=approved`);
        const tagsResponse = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/tags`);
        setArticles(articlesResponse.data.data);
        setTags(tagsResponse.data);
      } catch (error) {
        console.error("Error fetching articles or tags:", error);
      }
    };

    fetchArticlesAndTags();
  }, []);

  useEffect(() => {
    const handleVisibility = async () => {
      if (document.visibilityState === "visible") {
        try {
          const res = await axios.get(`${process.env.REACT_APP_IMGNBACKEND}/articles?reviewStatus=approved`);
          setArticles(res.data.data);
          console.log("🔁 Refetched articles on tab focus");
        } catch (err) {
          console.error("Error fetching articles on focus:", err);
        }
      }
    };
  
    document.addEventListener("visibilitychange", handleVisibility);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibility);
    };
  }, []);
  
  // Real-time updates via socket
  useEffect(() => {
    const socket = getSocket();
    if (!socket) return;

    const handleNewArticle = (article) => {
      if (!article || article.reviewStatus !== "approved") return;

      // Add article if not duplicate
      setArticles((prev) => {
        const exists = prev.some((a) => a._id === article._id);
        const updated = exists ? prev : [article, ...prev];
        return updated.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });

      // Add tag if not existing
      setTags((prevTags) => {
        const tagExists = prevTags.some((t) => t.tag === article.tag?.tag);
        return !tagExists && article.tag ? [...prevTags, article.tag] : prevTags;
      });

      // Show toast if matches filters
      const category = selectedCategoryRef.current;
      const tag = selectedTagRef.current;

      const isCategoryMatch =
        category === "الجميع" ||
        (category === "عام" && article.publishStatus.toLowerCase() === "common") ||
        (category === "الأحدث" && article.publishStatus.toLowerCase() === "latest") ||
        (category === "حصري" && article.publishStatus.toLowerCase() === "exclusive") ||
        (category === "عاجل" && article.publishStatus.toLowerCase() === "breaking");

      const isTagMatch = tag ? article.tag?.tag === tag : true;

      if (isCategoryMatch && isTagMatch && !toast.isActive(article._id)) {
        toast.info(`📢 تم نشر مقال جديد: "${article.title}"`, {
          toastId: article._id,
        });
      }
    };

    const handlePublishRequestReviewed = (article) => {
      if (!article || article.reviewStatus !== "approved") return;
      
      // Same logic as handleNewArticle
      setArticles((prev) => {
        const exists = prev.some((a) => a._id === article._id);
        const updated = exists ? prev : [article, ...prev];
        return updated.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      });

      // Add tag if not existing
      setTags((prevTags) => {
        const tagExists = prevTags.some((t) => t.tag === article.tag?.tag);
        return !tagExists && article.tag ? [...prevTags, article.tag] : prevTags;
      });

      // Show toast if matches filters
      const category = selectedCategoryRef.current;
      const tag = selectedTagRef.current;

      const isCategoryMatch =
        category === "الجميع" ||
        (category === "عام" && article.publishStatus.toLowerCase() === "common") ||
        (category === "الأحدث" && article.publishStatus.toLowerCase() === "latest") ||
        (category === "حصري" && article.publishStatus.toLowerCase() === "exclusive") ||
        (category === "عاجل" && article.publishStatus.toLowerCase() === "breaking");

      const isTagMatch = tag ? article.tag?.tag === tag : true;

      if (isCategoryMatch && isTagMatch && !toast.isActive(article._id)) {
        toast.info(`📢 تمت الموافقة على مقال جديد: "${article.title}"`, {
          toastId: article._id + "-reviewed",
        });
      }
    };

    const handleDeletedArticle = (deletedId) => {
      setArticles((prev) => prev.filter((a) => a._id !== deletedId));
      toast.warn("🗑️ تم حذف مقال");
    };

    const handleNewTag = (newTag) => {
      setTags((prev) => {
        const exists = prev.some((t) => t.tag === newTag.tag);
        return exists ? prev : [...prev, newTag];
      });
    };

    // Set up all event listeners
    socket.on("articlePublished", handleNewArticle);
    socket.on("publishRequestReviewed", handlePublishRequestReviewed);
    socket.on("articleDeleted", handleDeletedArticle);
    socket.on("tagCreated", handleNewTag);
    
    socket.once("connect", () => {
      console.log("🔌 Connected to socket in ArticlesPage");
    });
    
    socket.on("connect", () => {
      console.log("🔄 Socket reconnected to ArticlesPage");
    });

    return () => {
      socket.off("articlePublished", handleNewArticle);
      socket.off("publishRequestReviewed", handlePublishRequestReviewed);
      socket.off("articleDeleted", handleDeletedArticle);
      socket.off("tagCreated", handleNewTag);
      socket.off("connect");
    };
  }, []);

  const selectTag = (tag) => {
    setSelectedTag(tag === selectedTag ? "" : tag);
  };

  const clearFilters = () => {
    setSelectedCategory("الجميع");
    setSelectedTag("");
  };

  const filteredArticles = articles.filter((article) => {
    const isCategoryMatch =
      selectedCategory === "الجميع" ||
      (selectedCategory === "عام" && article.publishStatus.toLowerCase() === "common") ||
      (selectedCategory === "الأحدث" && article.publishStatus.toLowerCase() === "latest") ||
      (selectedCategory === "حصري" && article.publishStatus.toLowerCase() === "exclusive") ||
      (selectedCategory === "عاجل" && article.publishStatus.toLowerCase() === "breaking");

    const isTagMatch = selectedTag ? article.tag?.tag === selectedTag : true;

    return isCategoryMatch && isTagMatch;
  });

  const categories = ["عام", "حصري", "عاجل", "الأحدث", "الجميع"];

  const truncateDescription = (description, wordLimit) => {
    const words = description.split(' ');
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(' ') + '...';
    }
    return description;
  };

  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div>
      <Header />

      <section className="articles-page-section">
        <p className="articles-page-subtitle">
          يمكنك تصفية العرض عن طريق معدلات التفاعل أو السلسة المدرج بها المقال (أو كليهما)
        </p>

        <div className="articles-page-category-buttons">
          {categories.map((category) => (
            <button
              key={category}
              className={`category-button ${selectedCategory === category ? "active" : ""}`}
              onClick={() => setSelectedCategory(category)}
            >
              {category === "الجميع" ? category : capitalizeFirstLetter(category)}
            </button>
          ))}
        </div>

        <div className="articles-page-tags">
          {tags.map((tag) => (
            <button
              key={tag._id}
              className={`category-button ${selectedTag === tag.tag ? "active" : ""}`}
              onClick={() => selectTag(tag.tag)}
            >
              {capitalizeFirstLetter(tag.tag)}
            </button>
          ))}
        </div>

        {articles.length === 0 ? (
          <div className="no-articles-message">
            <p>لا توجد مقالات متاحة حاليًا. يرجى المراجعة لاحقًا.</p>
          </div>
        ) : filteredArticles.length === 0 ? (
          <div className="no-articles-message">
            <p>لا توجد مقالات متاحة لهذا الفلتر.</p>
            <button className="clear-filters-button" onClick={clearFilters}>إلغاء الفلتر</button>
          </div>
        ) : (
          <div className="articles-page-grid">
            {filteredArticles.map((article) => (
              <Link key={article._id} to={`/articles/${article.uniqueId}`} className="article-card-custom-link">
                <div className="article-card-custom">
                  <div className="article-card-custom-image-container">
                    <img src={article.cardImage} alt={article.title} className="article-card-custom-image" loading="lazy" />
                  </div>
                  <div className="article-card-custom-content">
                    <div className="article-tag-date">
                      {article.publishStatus && (
                        <span className={`article-tag-custom ${article.publishStatus.toLowerCase()}`}>
                          {capitalizeFirstLetter(article.publishStatus)}
                        </span>
                      )}
                      <p className="article-date">
                        {new Date(article.createdAt).toLocaleDateString()} at {new Date(article.createdAt).toLocaleTimeString()}
                      </p>
                    </div>
                    <h3 className="article-card-custom-title">{article.title}</h3>
                    <p className="article-card-custom-description">
                      {truncateDescription(article.description, 20)}
                    </p>
                    <div className="article-stats-custom">
                      <span className="article-likes">
                        {article.likes.length} <img src={test1} alt="Likes Icon" />
                      </span>
                      <span className="article-comments">
                        {article.comments.length} <img src={test2} alt="Comments Icon" />
                      </span>
                    </div>
                  </div>
                </div>
              </Link>
            ))}
          </div>
        )}

        <Footer />
      </section>
    </div>
  );
};

export default ArticlesPage;